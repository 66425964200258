import styled from "@emotion/styled";

export const ReviewChartsRoot = styled.div`
  margin-top: ${(props) => props.theme.spacing05};
  margin-right: ${(props) => props.theme.spacing05};
  margin-bottom: ${(props) => props.theme.spacing05};
  
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 920px auto;
`;

export const ReviewChartsTimeSeries = styled.div`
  display: grid; 
  grid-gap: 24px; 
  grid-template-rows: 456px auto;
`
