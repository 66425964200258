import * as React from 'react';
import { Dropdown, OnChangeData } from 'carbon-components-react';

import { ESGChart } from './molecules/ESGChart';
import { ChartTitle } from '../../../atoms/CharTitle';
import { ESGAxisTypes, ESGLabels } from '../../../../state/StrategyStateConstants';

import { DropDownContainer, ESGChartContainerRoot } from './ESGChartContainerStyles';

const ESGChartContainer = React.memo(() => {
  const [axis, setAxis] = React.useState<ESGAxisTypes>(ESGAxisTypes.CoP_year);

  const handleAxisChange = (value: OnChangeData<ESGAxisTypes>) => {
    setAxis(value.selectedItem!);
  };

  return (
    <ESGChartContainerRoot>
      <ChartTitle title="F3-B CoP year and CO2" marginLeft="42px" />

      <DropDownContainer>
        <Dropdown<ESGAxisTypes>
          id="time-series-axis"
          items={Object.values(ESGAxisTypes)}
          label=""
          titleText=""
          itemToString={(item) => ESGLabels[item]}
          selectedItem={axis}
          onChange={handleAxisChange}
        />
      </DropDownContainer>

      <ESGChart axis={axis} />
    </ESGChartContainerRoot>
  );
});

ESGChartContainer.displayName = 'ESGChartContainer';

export { ESGChartContainer };
