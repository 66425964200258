import { css } from '@emotion/react';

import { CarbonTheme } from '../../styles/theme';

export const reviewInputSettingsRoot = (theme: CarbonTheme) => css`
  padding: 0 ${theme.spacing05};

  .bx--label {
    font-size: 12px;
    margin-bottom: ${theme.spacing03};
    color: ${theme.text02};
  }

  .bx--dropdown,
  .bx--list-box {
    height: ${theme.spacing07};
    background-color: ${theme.ui01};
    color: ${theme.field02};
    margin-bottom: ${theme.spacing06};

    .bx--list-box__field:focus {
      outline: 2px solid white;
    }

    .bx--list-box__menu:focus {
      outline: 2px solid white;
    }

    .bx--list-box__label {
      font-size: 14px;
      color: ${theme.text01};
    }

    .bx--list-box__menu-icon > svg {
      fill: ${theme.interactive03};
    }

    .bx--list-box__menu {
      background-color: ${theme.ui01} !important;

      .bx--list-box__menu-item {
        .bx--list-box__menu-item__option {
          color: ${theme.text01} !important;
          border-top: solid 1px ${theme.activeSecondary};
        }
      }

      .bx--list-box__menu-item--highlighted,
      .bx--list-box__menu-item--active {
        background-color: ${theme.activeSecondary};

        .bx--list-box__menu-item__option {
          color: ${theme.text01} !important;
        }
      }
    }
  }
`;
