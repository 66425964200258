import * as React from 'react';

import {
  EconomicTradeOffsChartContainer,
  TimeSeriesChartContainer,
  ESGChartContainer,
  NonTechnicalRiskChartContainer,
} from './organisms';

import { StrategyChartsRoot, CenterColumn } from './StrategyChartsStyles';
import {ComplexityChartContainer} from "./organisms/ComplexitiyChartContainer";

const StrategyCharts = React.memo(() => {
  return (
    <StrategyChartsRoot>
      <EconomicTradeOffsChartContainer />

      <CenterColumn>
        <TimeSeriesChartContainer />
        <ESGChartContainer />
      </CenterColumn>

      <ComplexityChartContainer />
    </StrategyChartsRoot>
  );
});

StrategyCharts.displayName = 'StrategyCharts';

export { StrategyCharts };
