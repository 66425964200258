import * as React from 'react';

import { useStrategyState } from '../state/StrategyState';
import { fetchScenarioResult } from '../api/results';

export interface UseFetchEconomicResults {
  isFetching: boolean;
  fetchData: (scenarioId: string) => void;
  isError: boolean;
}

export const useFetchEconomicResults = (): UseFetchEconomicResults => {
  const {
    strategyEconomicResultsFetched,
    strategy: { strategies },
  } = useStrategyState();
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [isError, setError] = React.useState<boolean>(false);

  const fetchData = async (scenarioId: string) => {
    try {
      setFetching(true);

      const response = await fetchScenarioResult(scenarioId);
      const { data } = response;
      const strategyKeys = Object.keys(data);

      const filteredData = strategyKeys
        .map((key) => {
          return {
            ...data[key],
            scenarioId: scenarioId,
            strategyId: key,
            strategy: strategies[key],
          };
        })
        .filter((metric) => {
          return metric.profitability_index >= 0 && metric.strategy['Riser tower - when to take FID'] !== '2023';
        });

      const tradeOff = filteredData.filter((metric) => {
        return metric.profitability_index > 0;
      });

      console.log('Economic results data', filteredData);
      strategyEconomicResultsFetched(filteredData, tradeOff);
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    isFetching,
    fetchData,
    isError,
  };
};
