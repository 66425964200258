import styled from '@emotion/styled';

export const SmallWindowRoot = styled.div`
  display: grid;
  min-height: 100vh;
  place-items: center;
  width: 100vw;

  .bx--toast-notification__close-button {
    display: none;
  }
`;
