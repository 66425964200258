import styled from '@emotion/styled/macro';
import { css } from '@emotion/css';

const CELL_HEIGHT = 32;

export const StrategyChoiceRoot = styled.div`
  background-color: white;
  padding-left: 40px;
  padding-top: ${(props) => props.theme.spacing05};
`;

export const StrategyChoiceTitle = styled.div`
  height: 36px;
  margin: 0 32px 20px 0;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #161616;
`;

export const toggleIcon = css`
  cursor: pointer;
  position: absolute;
  right: 0;
  background-color: black;
  fill: white;
  top: -24px;
  width: 24px;
  height: 24px;
  padding: 4px;
`;

export const ToggleIconLine = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  fill: white;
  top: -24px;
  width: 24px;
  height: 24px;
  padding: 4px;
`;

export const ChoiceHeader = styled.div`
  position: relative;
  width: 1592px;
  height: ${(props) => props.theme.spacing07};
  padding: 7px 0 7px 0;
  background-color: #e0e0e0;
`;

export const ChoiceBody = styled.div`
  width: 1592px;
  background-color: white;
  height: 32px;
`;

interface ChoiceColumnHeaderProps {
  width: number;
  total?: number;
}

export const ChoiceColumnHeader = styled.div<ChoiceColumnHeaderProps>`
  width: ${(props) => {
    return props.width;
  }}px;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing10};
  height: 18px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #161616;
`;

export const ChoiceBodyCell = styled.div<ChoiceColumnHeaderProps>`
  position: relative;
  width: ${(props) => props.width}px;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing09};
  height: ${CELL_HEIGHT}px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
  color: #525252;
  border-bottom: 1px solid #eee;

  &:before {
    content: ' ';
    width: ${(props) => props.total!}px;
    position: absolute;
    right: ${(props) => props.width - 40}px;
    top: 8px;
    background-color: #e0e0e0;
    height: 16px;
  }
`;

export const Choice = styled.div`
  padding-top: ${(props) => props.theme.spacing03};
  padding-bottom: ${(props) => props.theme.spacing03};
  height: ${(props) => props.theme.spacing07};
  padding-left: ${(props) => props.theme.spacing05};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const unavailable = css`
  background-color: #eee;
  color: #eee;
`;

export const selected = css`
  background-color: #b3dee0;
`;

export const highlight = css`
  background-color: #097b90;
  color: #efefef;
`;

export const ChoiceBodyEmpty = styled.div<ChoiceColumnHeaderProps>`
  width: ${(props) => props.width}px;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing10};
  height: ${CELL_HEIGHT}px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
  color: #525252;
`;

StrategyChoiceRoot.displayName = 'StrategyChoice';
