import * as React from 'react';

import { ChartTitle } from '../../../atoms/CharTitle';
import {ComplexityChart} from "./molecules/ComplexitiyChart";

import { ComplexityChartContainerRoot } from './ComplexityChartContainerStyles';

const ComplexityChartContainer = React.memo(() => {
    return (
        <ComplexityChartContainerRoot>
            <ChartTitle title="Commercial vs technical complexity" marginLeft="80px" marginBottom="24px" />
            <ComplexityChart />
        </ComplexityChartContainerRoot>
    );
});

ComplexityChartContainer.displayName = 'ComplexityChartContainer';

export { ComplexityChartContainer };
