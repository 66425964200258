import { css } from '@emotion/css';

export const composedModal = css`
  .bx--modal-close__icon: {
    display: none;
  }
`;

export const logo = css`
  width: 25px;
  height: 25px;
  margin-left: 3px;
`;
