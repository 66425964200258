import { Action, ActionTypes } from '../context/ApplicationContextTypes';
import { NotificationState, NotificationType } from './NotificationStateTypes';

export const notificationReducer = (previousNotifications: NotificationState, action: Action): NotificationState => {
  if (action.type === ActionTypes.NotificationMessageTimeout) {
    return {
      ...previousNotifications,
      notificationType: NotificationType.disabled,
    };
  }

  if (action.type === ActionTypes.NotificationMessageCreated) {
    return {
      ...previousNotifications,
      ...action.data,
    };
  }

  return {
    ...previousNotifications,
  };
};
