import * as React from 'react';

import {
  ThemeProvider as EmotionThemeProvider,
  useTheme,
} from '@emotion/react';
import theme, { CarbonTheme } from '../styles/theme';

import "@emotion/react";

export interface ThemeProviderProps  {
  theme: Partial<CarbonTheme>;
}

const ThemeProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const computedTheme = theme;
  console.log(computedTheme);

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
};

export { ThemeProvider, useTheme };
