import * as React from 'react';
import { css } from '@emotion/css';

import { PriceSettings, StrategyLevels } from '../../state/StrategyStateConstants';
import { FormGroup, RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { RadioButtonValue } from 'carbon-components-react/lib/components/RadioButton';
import { RadioLevelSetting } from '../../state/StrategyStateTypes';
import { useTheme } from '../../context/ThemeContext';

export interface StrategyLevelSelectProps {
  setting: RadioLevelSetting;
  onValueChange: (newSelection: RadioButtonValue) => void;
  disabled?: boolean;
}

const StrategyLevelSelect = React.memo<StrategyLevelSelectProps>((props) => {
  const { setting, onValueChange, disabled = false } = props;
  const theme = useTheme();

  const StrategyLevelSelectRoot = css`
    margin-bottom: ${theme.spacing05};
  `;

  return (
    <FormGroup className={StrategyLevelSelectRoot} legendText={setting.title}>
      <RadioButtonGroup name={setting.name} valueSelected={setting.value} onChange={onValueChange} disabled={disabled}>
        <RadioButton id={`${setting.name}_carbon_low`} labelText="Low" value={StrategyLevels.low} disabled={disabled} />
        <RadioButton
          id={`${setting.name}_carbon_medium`}
          labelText="Mid"
          value={StrategyLevels.medium}
          disabled={disabled}
        />
        <RadioButton
          id={`${setting.name}_carbon_high`}
          labelText="High"
          value={StrategyLevels.high}
          disabled={disabled}
        />
      </RadioButtonGroup>
    </FormGroup>
  );
});

StrategyLevelSelect.displayName = 'StrategyLevelSelect';

export { StrategyLevelSelect };
