import * as React from 'react';
import {
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeriesCanvas,
  XAxis,
  XYPlot,
  YAxis,
  VerticalBarSeriesPoint,
  LineSeries,
  LineSeriesPoint,
  MarkSeriesCanvas,
  MarkSeriesPoint,
} from 'react-vis';

import { NonTechnicalRiskChartRoot } from './NonTechnicalRiskChartStyles';
import { useStrategyState } from '../../../../../state/StrategyState';
import { useTradeOffRange } from '../../../../../hooks/useTradeOffRange';
import {EconomicResultMetrics} from "../../../../../state/StrategyStateTypes";

const NonTechnicalRiskChart = React.memo((props) => {
  const { isInRange } = useTradeOffRange();
  const { strategy } = useStrategyState();
  const { highlightedStrategyChoice } = strategy;

  const handleGetColor = (point: MarkSeriesPoint) => {
    if (
      highlightedStrategyChoice &&
      point.strategy[highlightedStrategyChoice.choiceKey] === highlightedStrategyChoice.value
    ) {
      return '#B3DEE0';
    }

    // if (selectedStrategy && selectedStrategy.strategyId === point.strategyId) {
    //   return '#B3DEE0';
    // }

    return '#7E7E7E';
  };

  const handleGetOpacity = (point: MarkSeriesPoint) => {
    return isInRange(point as unknown as EconomicResultMetrics) ? 0.8 : 0.2;
  };

  // const data = filteredMetrics.map((metric) => {
  //   return {
  //     ...metric,
  //     x: metric.riskImpact,
  //     y: metric.riskProbability,
  //   };
  // });

  return (
    <NonTechnicalRiskChartRoot>
      <XYPlot width={480} height={416}>
        <HorizontalGridLines />
        <XAxis title="impact" />
        <YAxis title="probability" style={{ title: { transform: 'translate(34px,14px) rotate(0deg)' } }} />
        <MarkSeriesCanvas
          colorType="literal"
          getColor={handleGetColor}
          getOpacity={handleGetOpacity}
          data={[]}
          getX={(point) => point.riskImpact}
          getY={(point) => point.riskProbability}
        />
      </XYPlot>
    </NonTechnicalRiskChartRoot>
  );
});

NonTechnicalRiskChart.displayName = 'NonTechnicalRiskChart';

export { NonTechnicalRiskChart };
