import styled from '@emotion/styled';
import { css } from '@emotion/css';
import theme from '../../../../../styles/theme';

export const X_OFFSET_LEFT = 37;
export const X_OFFSET_BOTTOM = 37;
export const Y_OFFSET_LEFT = 75;
export const Y_OFFSET_BOTTOM = 0;

export const X_INPUT_WIDTH = 425;
export const Y_INPUT_HEIGHT = 375;

export const EconomicTradeOffsChartRoot = styled.div`
  position: relative;
  margin: 0;
  margin-top: 24px;
  margin-left: 16px;
`;

export const sliderInput = css`
  position: absolute;
  height: 1px;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    margin: 0;
    height: 1px;
    width: 100%;
    cursor: pointer;
    background: #979797;
  }

  &::-webkit-slider-thumb {
    width: 14px;
    -webkit-appearance: none;
    height: 14px;
    border-radius: 50%;
    cursor: pointer;
    background: #8d8d8d;
    z-index: 999;
    position: relative;
    margin-top: -6px;
    z-index: 1000;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: -4px;
    right: 0;
    border-radius: 50%;
    border: 1px solid #979797;
    background-color: #e0e0e0;
    z-index: 0;
  }

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${theme.activeSecondary};
  }
`;

export const xInput = css`
  bottom: ${X_OFFSET_BOTTOM}px;
  left: ${X_OFFSET_LEFT}px;
  width: ${X_INPUT_WIDTH}px;
  z-index: 100;

  -webkit-appearance: none;
  background-color: ${theme.activeSecondary};
  margin-top: 2px;
`;

export const yInput = css`
  position: absolute;
  bottom: ${Y_OFFSET_BOTTOM + 225}px;
  left: ${Y_OFFSET_LEFT - 225}px;
  width: ${Y_INPUT_HEIGHT}px;
  z-index: 100;
  transform: rotate(270deg);
`;

export interface TooltipProps {
  left: number;
  top: number;
  display: string;
}

const LEFT_OFFSET = -230;
const TOP_OFFSET = -200;

export const Tooltip = styled.div<TooltipProps>`
  z-index: 300;
  position: absolute;
  top: ${(props) => props.top + TOP_OFFSET}px;
  left: ${(props) => props.left + LEFT_OFFSET}px;
  display: ${(props) => props.display};
  background-color: #f4f4f4;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 5px;

  table {
    width: 600px;

    tr {
      height: auto;

      &:last-child td {
        border-bottom: none;
      }

      td {
        font-size: 12px;
        padding: 4px;
      }
    }
  }
`;
