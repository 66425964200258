import styled from '@emotion/styled';

export const AssetMapRoot = styled.div`
  position: absolute;
  top: 74px;
  bottom: 32px;
  left: 32px;
  right: 32px;

  img {
    height: 100%;
    max-width: 670px;
  }
`;

export const OverlayContainer = styled.div`
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
`;

export interface LocatorProps {
  visible?: boolean;
}

export const Locator = styled.div<LocatorProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
`;

export interface LocatorSquaresProps {
  width: number;
  height: number;
  left: number;
  top: number;
}

export const LocatorSquare = styled.div<LocatorSquaresProps>`
  display: block;
  position: absolute;
  background-color: rgba(0, 250, 247, 0.5);
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

export const Locators = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export interface PopupProps {
  left: number;
  top: number;
}

export const Popup = styled.div<PopupProps>`
  display: block;
  position: relative;
  background-color: #ddd;
  opacity: 0.9;
  padding: 16px;
  width: 280px;
  border-radius: 6px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

export const PopupTitle = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 16px;
`;

export const PopupItems = styled.div`
  border: 1px solid #777;
  background-color: #777;

  > div {
    display: flex;
    justify-content: space-between;

    div {
      background-color: white;
      width: 50%;
      margin: 1px;
      padding: 8px;
    }
  }
`;
