import * as React from 'react';
import {
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderName,
  SkipToContent,
  HeaderNavigation,
} from 'carbon-components-react';
import { Link, LinkProps, useHistory } from 'react-router-dom';

import { useSessionState } from '../../state/SessionState';
import { Header } from '../organisms/Header';
import { useTheme } from '../../context/ThemeContext';
import Logout from '@carbon/icons-react/lib/logout/20';
import PDF from '@carbon/icons-react/lib/document--pdf/20';

import logoNeptune from '../../img/th.jpg';

import * as css from './AppHeaderStyles';

const AppHeader: React.FC = React.memo(() => {
  const theme = useTheme();
  const { logout } = useSessionState();

  const handleLogout = () => {
    logout();
  };

  const handleDownload = () => {
    window.open('/Neptune_F3B_assumptions_April2021_rev1.pdf');
  };

  return (
    <Header>
      <img className={css.image} src={logoNeptune} alt="Equinor" />
      <SkipToContent />
      <HeaderName<LinkProps> element={Link} to="/" aria-label="project title" prefix="">
        Neptune <span className={css.headerNameTitle}>NL Hub strategy decision support</span>
      </HeaderName>

      <HeaderGlobalBar className={css.headerGlobalBar(theme)}>
        <HeaderGlobalAction aria-label="Search" onClick={handleDownload}>
          <PDF />
        </HeaderGlobalAction>
        <HeaderGlobalAction aria-label="Search" onClick={handleLogout}>
          <Logout />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
});

AppHeader.displayName = 'AppHeader';

export { AppHeader };
