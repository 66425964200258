import * as React from 'react';

import { Dropdown, OnChangeData } from 'carbon-components-react';

import { SettingsPane } from '../molecules/SettingsPane';
import { useReviewState } from '../../state/ReviewState';
import { useTheme } from '../../context/ThemeContext';
import { CostCategoryLabels, ProductionCategoryLabels, TimingLabels, TimingTypes } from '../../state/ReviewStateTypes';

import { reviewInputSettingsRoot } from './ReviewInputSettingsStyles';

const ReviewInputSettings = React.memo(() => {
  const {
    review,
    assetChanged,
    projectChanged,
    timingChanged,
    costCategoryChanged,
    productionCategoryChanged,
  } = useReviewState();
  const theme = useTheme();

  const handleChangeAsset = (data: OnChangeData<string>) => {
    assetChanged(data.selectedItem || null);
  };

  const handleChangeProject = (data: OnChangeData<string>) => {
    projectChanged(data.selectedItem || null);
  };

  const handleChangeProductionCategory = (data: OnChangeData<string>) => {
    productionCategoryChanged(data.selectedItem!);
  };

  const handleChangeCostCategory = (data: OnChangeData<string>) => {
    costCategoryChanged(data.selectedItem!);
  };

  const handleChangeTiming = (data: OnChangeData<TimingTypes>) => {
    timingChanged(data.selectedItem!);
  };

  const timingTypes = [TimingTypes.none, TimingTypes.low, TimingTypes.medium, TimingTypes.high];

  const getTimingLabel = (timing: TimingTypes) => {
    return TimingLabels[timing];
  };

  return (
    <SettingsPane css={reviewInputSettingsRoot(theme)}>
      <Dropdown<string>
        id="select-asset"
        titleText="Select asset"
        label="Choose an option"
        selectedItem={review.asset}
        items={review.assets}
        onChange={handleChangeAsset}
      />

      <Dropdown<string>
        disabled={!review.asset}
        id="select-project"
        titleText="Select project"
        label="Choose an option"
        selectedItem={review.project}
        items={review.projects}
        onChange={handleChangeProject}
      />

      <Dropdown<string>
        id="select-production-category"
        titleText="Select production category"
        label=""
        selectedItem={review.productionCategory}
        items={review.productionCategories}
        itemToString={(item) => ProductionCategoryLabels[item]}
        onChange={handleChangeProductionCategory}
      />

      <Dropdown<string>
        id="select-cost-category"
        titleText="Select cost category"
        label=""
        selectedItem={review.costCategory}
        items={review.costCategories}
        itemToString={(item) => CostCategoryLabels[item]}
        onChange={handleChangeCostCategory}
      />

      {/*<Dropdown<TimingTypes>*/}
      {/*  id="select-cost-category"*/}
      {/*  titleText="Timing"*/}
      {/*  label=""*/}
      {/*  itemToString={getTimingLabel}*/}
      {/*  selectedItem={review.timing}*/}
      {/*  items={timingTypes}*/}
      {/*  onChange={handleChangeTiming}*/}
      {/*/>*/}
    </SettingsPane>
  );
});

ReviewInputSettings.displayName = 'ReviewInputChoices';

export { ReviewInputSettings };
