import { useStrategyState } from '../state/StrategyState';
import { EconomicResultMetrics } from '../state/StrategyStateTypes';

export const useTradeOffRange = (): {
  x: number;
  yLog: number;
  isInRange: (metric: EconomicResultMetrics) => boolean;
} => {
  const { strategy } = useStrategyState();

  const { x, yLog, y } = strategy.economicSettings.tradeOffRange;

  const isInRange = (metric: EconomicResultMetrics): boolean => {
    return metric.discounted_cashflow > x || metric.profitability_index > yLog;
  };

  return {
    isInRange,
    x,
    yLog,
  };
};
