import * as React from 'react';
import { Table, TableBody, TableRow, TableCell } from 'carbon-components-react';
import {
  XYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  MarkSeriesPoint,
  MarkSeries,
  ChartLabel,
  RVTickFormat,
  RectSeries,
  VerticalRectSeries,
} from 'react-vis';

import { useTradeOffRange } from '../../../../../hooks/useTradeOffRange';
import { useStrategyState } from '../../../../../state/StrategyState';
import { EconomicResultMetrics, Range } from '../../../../../state/StrategyStateTypes';
import { getPoint, getValue } from '../../../../../utils/Log';
import { getFilteredData, getRange, getTickValues } from '../../../../../utils/TradeOffChart';
import { StrategyChoiceDisplay } from '../../../../../state/StrategyStateConstants';

import { EconomicTradeOffsChartRoot, xInput, yInput, sliderInput, Tooltip } from './EconomicTradeOffsChartStyles';

export interface AxesRange {
  x: Range;
  y: Range;
}

export interface EconomicTradeOffsChartProps {
  isLog: boolean;
}

const EconomicTradeOffsChart = React.memo<EconomicTradeOffsChartProps>((props) => {
  const { isLog } = props;
  //const nearestXYRef = React.useRef<MarkSeriesPoint | null>(null);
  const [nearestXYRef, setNearestXYRef] = React.useState<MarkSeriesPoint | null>(null);
  const [mouseOverXY, setMouseOverXY] = React.useState<MarkSeriesPoint | null>(null);

  const {
    strategy: {
      filteredResultTradeOff,
      filteredResultTrigger,
      economicSettings: { tradeOffRange },
      highlightedStrategyChoice,
      selectedStrategyId,
      strategies,
    },
    tradeOffsRangeChanged,
    strategySelectedChanged,
  } = useStrategyState();

  const { isInRange, x: axisX, yLog: axisY } = useTradeOffRange();

  const onChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    tradeOffsRangeChanged({
      x: parseFloat(event.target.value),
      y: tradeOffRange.y,
      yLog: tradeOffRange.yLog,
    });
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!nearestXYRef) {
      strategySelectedChanged('');

      return;
    }

    strategySelectedChanged(nearestXYRef.strategyId);
  };

  const handleNearestXY = (point: MarkSeriesPoint) => {
    setNearestXYRef(point);
  };

  const handleGetColor = (point: MarkSeriesPoint) => {
    if (
      highlightedStrategyChoice &&
      point.strategy[highlightedStrategyChoice.choiceKey] === highlightedStrategyChoice.value
    ) {
      return '#097b90';
    }

    if (selectedStrategyId && selectedStrategyId === point.strategyId) {
      return '#B3DEE0';
    }

    if (!isInRange((point as unknown) as EconomicResultMetrics)) {
      return '#DADADA';
    }

    return '#7E7E7E';
  };

  const handleGetOpacity = (point: MarkSeriesPoint) => {
    if (
      highlightedStrategyChoice &&
      point.strategy[highlightedStrategyChoice.choiceKey] === highlightedStrategyChoice.value
    ) {
      return 1;
    }

    if (selectedStrategyId && selectedStrategyId === point.strategyId) {
      return 1;
    }

    return isInRange((point as unknown) as EconomicResultMetrics) ? 0.3 : 1;
  };

  const { data, range, tickValues } = React.useMemo(() => {
    const filteredData = getFilteredData(filteredResultTradeOff, selectedStrategyId, isLog);
    const range = getRange(filteredData);
    const sizes = filteredData.reduce<{ min: number; max: number }>(
      (accumulator, nextMetric) => {
        return {
          min: Math.min(accumulator.min, nextMetric.cumulative_production),
          max: Math.max(accumulator.max, nextMetric.cumulative_production),
        };
      },
      { min: Infinity, max: 0 },
    );

    const delta = sizes.max - sizes.min;
    const sizeMultiplier = 15 / delta;

    const data = filteredData.map((metric) => {
      return {
        ...metric,
        x: metric.discounted_cashflow,
        y: isLog
          ? getPoint(metric.profitability_index, { minValue: range.y.min, maxValue: range.y.max })
          : metric.profitability_index,
        size: metric.cumulative_production * sizeMultiplier,
        strategy: strategies[metric.strategyId!],
      };
    });

    const tickValues = getTickValues(range);

    return {
      data,
      range,
      tickValues,
    };
  }, [filteredResultTrigger, selectedStrategyId, isLog]);

  React.useEffect(() => {
    tradeOffsRangeChanged({
      x: 0,
      y: range.y.min,
      yLog: range.y.min,
    });
  }, [filteredResultTrigger, isLog]);

  const handleTickFormat = (value: string, index: number) => {
    return `${tickValues[index].value}`;
  };

  const onChangeY = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const point = parseFloat(event.target.value);

    tradeOffsRangeChanged({
      x: tradeOffRange.x,
      y: point,
      yLog: isLog ? getValue(point, { minValue: range.y.min, maxValue: range.y.max }) : point,
    });
  };

  const handleMouseOut = () => {
    setMouseOverXY(null);
  };

  const handleMouseOver = (point: MarkSeriesPoint, rvEvent: React.MouseEvent<HTMLElement>) => {
    const eventCopy = rvEvent as any;
    const syntheticEvent: React.SyntheticEvent = eventCopy.event;
    const rect = syntheticEvent.currentTarget.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const result = filteredResultTradeOff.filter((metric) => {
      return (
        metric.discounted_cashflow === point.discounted_cashflow &&
        metric.profitability_index === point.profitability_index
      );
    });

    setMouseOverXY({
      ...point,
      left: rect.left,
      top: rect.top,
      items: result,
    });
  };

  const roundedRange: AxesRange = {
    x: {
      min: Math.floor(range.x.min),
      max: Math.ceil(range.x.max),
    },
    y: {
      min: isLog ? range.y.min : Math.floor(range.y.min),
      max: Math.ceil(range.y.max),
    },
  };

  return (
    <EconomicTradeOffsChartRoot onClick={handleClick}>
      {mouseOverXY && (
        <Tooltip display="block" left={mouseOverXY!.left} top={mouseOverXY!.top}>
          <Table>
            <TableBody>
              {mouseOverXY.items.map((item: EconomicResultMetrics) => {
                return (
                  <TableRow key={item.strategyId}>
                    {/*<TableCell>{item.strategyId}</TableCell>*/}
                    {StrategyChoiceDisplay.map((key) => {
                      return <TableCell key={key}>{item.strategy![key]}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Tooltip>
      )}
      <input
        type="range"
        min={roundedRange.x.min}
        max={roundedRange.x.max}
        step={0.1}
        className={[sliderInput, xInput].join(' ')}
        value={tradeOffRange.x}
        onChange={onChangeX}
      />
      <input
        onChange={onChangeY}
        type="range"
        min={isLog ? 0 : roundedRange.y.min}
        max={isLog ? 100 : roundedRange.y.max}
        step={0.01}
        className={[sliderInput, yInput].join(' ')}
        aria-orientation={'vertical'}
        value={tradeOffRange.y}
      />
      <XYPlot
        width={468}
        height={416}
        xDomain={[roundedRange.x.min, roundedRange.x.max]}
        yDomain={isLog ? [0, 100] : [roundedRange.y.min, roundedRange.y.max]}
      >
        <HorizontalGridLines tickValues={isLog ? tickValues.map((tickValue) => tickValue.point) : undefined} />
        <XAxis title="Discount cash flow [MEUR]" tickLabelAngle={-45} />
        <YAxis
          tickValues={isLog ? tickValues.map((tickValue) => tickValue.point) : undefined}
          tickFormat={isLog ? (handleTickFormat as RVTickFormat) : undefined}
          title="Profitability index [-]"
          style={{
            title: {
              textAnchor: 'start',
              transform: 'translate(6px,6px) rotate(0deg)',
            },
          }}
        />
        <MarkSeries
          sizeType="literal"
          onNearestXY={handleNearestXY}
          colorType="literal"
          getColor={handleGetColor}
          getOpacity={handleGetOpacity}
          data={data}
          // onValueMouseOver={handleMouseOver}
          // onValueMouseOut={handleMouseOut}
        />
        <VerticalRectSeries
          data={[
            {
              x: axisX,
              x0: range.x.min,
              y: isLog ? getPoint(axisY, { minValue: range.y.min, maxValue: range.y.max }) : axisY,
              y0: range.y.min,
            },
          ]}
          opacity={0.2}
          colorType="literal"
          color="#AAA"
        />
        <ChartLabel text="size = Cumulative production" xPercent={0.65} yPercent={-0.044} />
      </XYPlot>
    </EconomicTradeOffsChartRoot>
  );
});

EconomicTradeOffsChart.displayName = 'EconomicTradeOffsChart';

export { EconomicTradeOffsChart };
