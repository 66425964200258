import styled from '@emotion/styled/macro';
import { css } from '@emotion/css';
import { CarbonTheme } from '../../styles/theme';

export const accordionItem = (theme: CarbonTheme) => css`
  .bx--accordion__content {
    padding: ${theme.spacing05};
  }
`;

export const discountRateFormGroup = (theme: CarbonTheme) => css`
  margin-bottom: ${theme.spacing05};
`;

export const HR = styled.hr`
  width: 192px;
  height: 1px;
  border: 0;
  margin-bottom: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.activeSecondary};
`;
