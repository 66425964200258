import * as React from 'react';

import { getElementDimension } from './Dimensions';

export const locations: Location[] = [
  {
    id: 1,
    x: 0.478,
    y: 0.13,
    squares: [
      { x: 0.38, y: 0.07, width: 0.11, height: 0.077 },
      { x: 0.49, y: 0.11, width: 0.095, height: 0.037 },
      { x: 0.44, y: 0.147, width: 0.145, height: 0.035 },
    ],
    name: 'F3-C',
    items: [
      {
        name: 'Dana',
        value: '45,00%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
      {
        name: 'Neptune',
        value: '15,00%',
      },
    ],
  },
  {
    id: 2,
    x: 0.39,
    y: 0.18,
    squares: [{ x: 0.35, y: 0.15, width: 0.08, height: 0.05 }],
    name: 'F3-FB LMG Unit',
    items: [
      {
        name: 'Neptune',
        value: '58,96%',
      },
      {
        name: 'TAQA',
        value: '23,44%',
      },
      {
        name: 'ONE-Dyas',
        value: '12,53%',
      },
      {
        name: 'Total',
        value: '4,68%',
      },
      {
        name: 'Vermillion',
        value: '0,39%',
      },
    ],
  },
  {
    id: 3,
    x: 0.28,
    y: 0.13,
    name: 'F2a (Hanze)',
    squares: [
      { x: 0.27, y: 0.07, width: 0.11, height: 0.06 },
      { x: 0.18, y: 0.13, width: 0.2, height: 0.06 },
    ],
    items: [
      {
        name: 'Dana',
        value: '45,00%',
      },
      {
        name: 'ONE-Dyas',
        value: '35,00%',
      },
      {
        name: 'Viaro Energy',
        value: '20,00%',
      },
    ],
  },
  {
    id: 4,
    x: 0.28,
    y: 0.26,
    name: 'F5 & F4 East',
    squares: [{ x: 0.185, y: 0.19, width: 0.205, height: 0.115 }],
    items: [
      {
        name: 'Neptune',
        value: '28,33%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
      {
        name: 'Halo',
        value: '21,67%',
      },
      {
        name: 'NAM',
        value: '10,00%',
      },
    ],
  },
  {
    id: 5,
    x: 0.09,
    y: 0.25,
    name: 'F4 West & Shallow',
    squares: [{ x: 0, y: 0.19, width: 0.19, height: 0.115 }],
    items: [
      {
        name: 'Neptune',
        value: '20,00%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
      {
        name: 'NAM',
        value: '30,00%',
      },
      {
        name: 'Halo',
        value: '10,00%',
      },
    ],
  },
  {
    id: 6,
    x: 0.3,
    y: 0.73,
    name: 'F17',
    squares: [{ x: 0.2, y: 0.655, width: 0.2, height: 0.115 }],
    items: [
      {
        name: 'WINZ',
        value: '30,00%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
      {
        name: 'Neptune',
        value: '20,00%',
      },
      {
        name: 'TAQA',
        value: '5,00%',
      },
      {
        name: 'Rosewood',
        value: '5,00%',
      },
    ],
  },
  {
    id: 7,
    x: 0.5,
    y: 0.24,
    name: 'F6-B',
    squares: [{ x: 0.385, y: 0.185, width: 0.2, height: 0.115 }],
    items: [
      {
        name: 'ONE-Dyas',
        value: '24,00%',
      },
      {
        name: 'Dana',
        value: '36,00%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
    ],
  },
  {
    id: 8,
    x: 0.31,
    y: 0.96,
    name: 'L5',
    squares: [{ x: 0.205, y: 0.89, width: 0.2, height: 0.110 }],
    items: [
      {
        name: 'Neptune',
        value: '60,00%',
      },
      {
        name: 'EBN',
        value: '40,00%',
      },
    ],
  },
];

const DISTANCE_THRESHOLD = 0.07;

const findLocation = (x: number, y: number): Location | null => {
  const filtered = locations
    .map((location) => {
      return { ...location, distance: Math.sqrt(Math.pow(location.x - x, 2) + Math.pow(location.y - y, 2)) };
    })
    .filter((location) => {
      return location.distance < DISTANCE_THRESHOLD;
    });

  if (filtered.length === 0) {
    return null;
  }

  return filtered.reduce<Location | null>((accumulator, next) => {
    if (!accumulator || next.distance < accumulator.distance!) {
      return next;
    }

    return accumulator;
  }, null);
};

export interface Location {
  id: number;
  x: number;
  y: number;
  distance?: number;
  name: string;
  items: { name: string; value: string }[];
  squares: { x: number; y: number; width: number; height: number }[];
}

export interface UseImageMap {
  location: Location | null;
  locatorStyle: React.CSSProperties;
  locations: Location[];
  mapDimension: {
    width: number;
    height: number;
  };
}

export const useImageMap = (
  ref: React.RefObject<HTMLImageElement>,
  containerDimension: { width: number; height: number },
): UseImageMap => {
  const [location, setLocation] = React.useState<Location | null>(null);

  const handleMouseMove = (event: MouseEvent) => {
    const imageDimension = getElementDimension(ref);

    const { offsetX, offsetY } = event;
    const x = offsetX / imageDimension.width;
    const y = offsetY / imageDimension.height;

    setLocation(findLocation(x, y));
  };

  const handleClick = (event: MouseEvent) => {
    const { offsetX, offsetY } = event;
    const x = offsetX / imageDimension.width;
    const y = offsetY / imageDimension.height;

    console.log(x.toFixed(2), y.toFixed(2));
  };

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('mousemove', handleMouseMove);
    ref.current.addEventListener('click', handleClick);

    return () => {
      if (!ref.current) {
        return;
      }

      ref.current.removeEventListener('mousemove', handleMouseMove);
      ref.current.removeEventListener('click', handleClick);
    };
  });

  const locatorStyle: React.CSSProperties = {};
  const imageDimension = getElementDimension(ref);

  if (location) {
    locatorStyle.display = 'block';
    locatorStyle.left = `${-60 + imageDimension.width * location.x}px`;
    locatorStyle.top = `${-60 + imageDimension.height * location.y}px`;
  }

  return {
    locations,
    mapDimension: imageDimension,
    location,
    locatorStyle,
  };
};
