import * as React from 'react';

import { LoginStatus, SessionState } from '../state/SessionStateTypes';
import { NotificationState } from '../state/NotificationStateTypes';
import { NotificationCreated, NotificationTimeout } from '../state/NotificationState';
import { StrategyState } from '../state/StrategyStateTypes';
import {
  ConceptSliderChanged,
  DiscountRateChanged,
  EconomicLevelChanged,
  F3ToggleChanged,
  GeneralToggleChanged,
  NeptuneLevelChanged,
  ScenarioMappingFetched,
  StrategyComplexitiesFetched,
  StrategyEconomicResultsFetched,
  StrategyHighlightedChoiceChanged,
  StrategyMappingFetched,
  StrategyParameterSettingsChanged,
  StrategySelectedChanged,
  TimeSeriesAxisChanged,
  TradeOffsRangeChanged,
} from '../state/StrategyState';
import { ReviewState } from '../state/ReviewStateTypes';
import {
  ReviewAssetChanged,
  ReviewAssetProjectTimeSeriesChanged,
  ReviewCostCategoryChanged,
  ReviewProductionCategoryChanged,
  ReviewProjectChanged,
  ReviewTimingChanged,
} from '../state/ReviewState';

export interface ApplicationState {
  notifications: NotificationState;
  review: ReviewState;
  session: SessionState;
  strategy: StrategyState;
}

export interface ApplicationProviderProps {
  children: React.ReactNode;
}

export interface LoginStatusAction {
  type: ActionTypes.SetLoginStatus;
  data: LoginStatus;
}

export type Action =
  | LoginStatusAction
  | NotificationCreated
  | NotificationTimeout
  | ConceptSliderChanged
  | GeneralToggleChanged
  | F3ToggleChanged
  | EconomicLevelChanged
  | DiscountRateChanged
  | TradeOffsRangeChanged
  | StrategySelectedChanged
  | StrategyHighlightedChoiceChanged
  | ReviewAssetChanged
  | ReviewProjectChanged
  | ReviewProductionCategoryChanged
  | ReviewCostCategoryChanged
  | ReviewTimingChanged
  | StrategyMappingFetched
  | ScenarioMappingFetched
  | StrategyComplexitiesFetched
  | StrategyEconomicResultsFetched
  | StrategyParameterSettingsChanged
  | TimeSeriesAxisChanged
  | NeptuneLevelChanged
  | ReviewAssetProjectTimeSeriesChanged;

export interface ContextValue {
  state: ApplicationState | null;
  dispatch: React.Dispatch<Action>;
}

export enum ActionTypes {
  SetLoginStatus = 'SET_LOGIN_STATUS',
  SetLoginLoggedOn = 'SET_LOGIN_LOGGED_ON',
  SetLoginLoggedOff = 'SET_LOGIN_LOGGED_OFF',
  SetLoginLoggedValidating = 'SET_LOGIN_VALIDATING',

  NotificationMessageCreated = 'NOTIFICATION_MESSAGE_CREATED',
  NotificationMessageTimeout = 'NOTIFICATION_MESSAGE_TIMEOUT',

  ReviewAssetChanged = 'REVIEW_ASSET_CHANGED',
  ReviewProjectChanged = 'REVIEW_PROJECT_CHANGED',
  ReviewProductionCategoryChanged = 'REVIEW_PRODUCTION_CATEGORY_CHANGED',
  ReviewCostCategoryChanged = 'REVIEW_COST_CATEGORY_CHANGED',
  ReviewTimingChanged = 'REVIEW_TIMING_CHANGED',
  ReviewAssetProjectTimeSeriesChanged = 'REVIEW_ASSET_PROJECT_TIME_SERIES_CHANGED',

  StrategyScenarioSliderChanged = 'STRATEGY_SCENARIO_SLIDER_CHANGED',
  StrategyGeneralToggleChanged = 'STRATEGY_GENERAL_TOGGLE_CHANGED',
  StrategyF3ToggleChanged = 'STRATEGY_F3_TOGGLE_CHANGED',
  StrategyEconomicLevelChanged = 'STRATEGY_ECONOMIC_LEVEL_CHANGED',
  StrategyDiscountRateChanged = 'STRATEGY_DISCOUNT_RATE_CHANGED',
  StrategyEconomicTradeOffsRangeChanged = 'STRATEGY_ECONOMIC_TRADE_OFFS_RANGE_CHANGED',
  StrategySelectedChanged = 'STRATEGY_SELECTED_CHANGED',
  StrategyHighlightedChoiceChanged = 'STRATEGY_HIGHLIGHTED_CHOICE_CHANGED',
  StrategyMappingFetched = 'STRATEGY_MAPPING_FETCHED',
  StrategyScenarioMappingFetched = 'STRATEGY_SCENARIO_MAPPING_FETCHED',
  StrategyEconomicResultsFetched = 'STRATEGY_ECONOMIC_RESULTS_FETCHED',
  StrategyComplexitiesFetched = 'STRATEGY_COMPLEXITIES_FETCHED',
  StrategyParameterSettingsChanged = 'STRATEGY_PARAMETER_SETTINGS_CHANGED',
  StrategyTimeSeriesAxisChanged = 'STRATEGY_TIME_SERIES_AXIS_CHANGED',
  StrategyNeptuneLevelChanged = 'STRATEGY_NEPTUNE_LEVEL_CHANGED',
}
