import * as React from 'react';
import { Header as CarbonHeader } from 'carbon-components-react/lib/components/UIShell';
import { useTheme } from '../../context/ThemeContext';

import * as css from './HeaderStyles';

const Header: React.FC = (props): React.ReactElement => {
  const { children } = props;
  const theme = useTheme();

  return (
    <CarbonHeader className={css.headerRoot(theme)} aria-label="project header">
      {children}
    </CarbonHeader>
  );
};

export { Header };
