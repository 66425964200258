export interface LogOptions {
  minPoint?: number;
  maxPoint?: number;
  minValue?: number;
  maxValue?: number;
}

export const getScale = (options: LogOptions): number => {
  return (options.maxValue! - options.minValue!) / (options.maxPoint! - options.minPoint!);
};

const MIN_VALUE = 0.08;

export const getValue = (point: number, options: LogOptions): number => {
  const { minPoint = 0, maxPoint = 100 } = options;
  const minValue = Math.log(options.minValue || MIN_VALUE);
  const maxValue = Math.log(options.maxValue || 100);

  return Math.exp((point - minPoint) * getScale({ minValue, minPoint, maxPoint, maxValue }) + minValue);
};

export const getPoint = (value: number, options: LogOptions): number => {
  const { minPoint = 0, maxPoint = 100 } = options;
  const minValue = Math.log(options.minValue || MIN_VALUE);
  const maxValue = Math.log(options.maxValue || 100);

  return minPoint + (Math.log(value) - minValue) / getScale({ minValue, minPoint, maxPoint, maxValue });
};
