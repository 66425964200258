import { Action, ActionTypes } from '../context/ApplicationContextTypes';
import {
  AssetProjectTimeSeries,
  CostCategoryLabels,
  ProductionCategoryLabels,
  ReviewState,
  TimingTypes,
  TimingValues,
} from './ReviewStateTypes';

const getUniqueAssets = (series: AssetProjectTimeSeries[]): string[] => {
  return series.map((serie) => serie.asset).filter((asset, index, self) => self.indexOf(asset) === index);
};

const getUniqueProjects = (series: AssetProjectTimeSeries[], asset: string | null): string[] => {
  return series
    .filter((serie) => !asset || serie.asset === asset)
    .map((serie) => serie.projectName)
    .filter((asset, index, self) => self.indexOf(asset) === index);
};

interface GetUnitMapping {
  [key: string]: string;
}

const getUnitMapping = (series: AssetProjectTimeSeries[], categories: string[]): GetUnitMapping => {
  const mapping = categories.reduce<{ [key: string]: string }>((accumulator, nextCatergory) => {
    const serie = series.find((findSerie) => findSerie.category === nextCatergory);

    if (!serie) {
      return {
        ...accumulator,
      };
    }

    return {
      ...accumulator,
      [nextCatergory]: serie.unit,
    };
  }, {});

  return mapping;
};

const getUniqueProductionCategories = (series: AssetProjectTimeSeries[], asset: string, project: string): string[] => {
  const productionCategories = Object.keys(ProductionCategoryLabels);

  // series.filter((serie) => {
  //   const time = Object.keys(serie.timeseries).map((key) => serie.timeseries[key]);
  //   const result = time.filter((item, index, self) => self.indexOf(item) === index);
  // });

  return series
    .filter((serie) => {
      return (
        serie.projectName === project && serie.asset === asset && productionCategories.indexOf(serie.category) >= 0
      );
    })
    .map((serie) => serie.category)
    .filter((category, index, self) => self.indexOf(category) === index);
};

const getUniqueCostCategories = (series: AssetProjectTimeSeries[], asset: string, project: string): string[] => {
  const costCategories = Object.keys(CostCategoryLabels);

  return series
    .filter(
      (serie) => serie.projectName === project && serie.asset === asset && costCategories.indexOf(serie.category) >= 0,
    )
    .map((serie) => serie.category)
    .filter((category, index, self) => self.indexOf(category) === index);
};

export const reviewReducer = (previousReview: ReviewState, action: Action): ReviewState => {
  if (action.type === ActionTypes.ReviewAssetProjectTimeSeriesChanged) {
    const assets = getUniqueAssets(action.data.series);
    const asset = 'F3 LGMG';
    const projects = getUniqueProjects(action.data.series, asset);
    const project = 'base';

    const productionCategories = getUniqueProductionCategories(action.data.series, asset, project);
    const costCategories = getUniqueCostCategories(action.data.series, asset, project);

    return {
      ...previousReview,
      assetProjectTimeSeries: action.data.series,
      assets,
      asset,
      projects,
      project,
      categoryLabelUnitMapping: getUnitMapping(action.data.series, [
        ...previousReview.productionCategories,
        ...previousReview.costCategories,
      ]),
      productionCategories,
      costCategories,
      productionCategory: productionCategories[0],
      costCategory: costCategories[0],
    };
  }

  if (action.type === ActionTypes.ReviewAssetChanged) {
    const asset = action.data.asset;
    const projects = getUniqueProjects(previousReview.assetProjectTimeSeries, asset);
    const project =
      previousReview.project && projects.indexOf(previousReview.project) >= 0 ? previousReview.project : projects[0];
    const productionCategories = getUniqueProductionCategories(previousReview.assetProjectTimeSeries, asset!, project);
    const productionCategory =
      productionCategories.indexOf(previousReview.productionCategory) >= 0
        ? previousReview.productionCategory
        : productionCategories[0];
    const costCategories = getUniqueCostCategories(previousReview.assetProjectTimeSeries, asset!, project);
    const costCategory =
      productionCategories.indexOf(previousReview.costCategory) >= 0 ? previousReview.costCategory : costCategories[0];

    return {
      ...previousReview,
      asset: asset,
      projects,
      project,
      productionCategories,
      productionCategory,
      costCategories,
      costCategory,
    };
  }

  if (action.type === ActionTypes.ReviewProjectChanged) {
    const { project } = action.data;

    const productionCategories = getUniqueProductionCategories(
      previousReview.assetProjectTimeSeries,
      previousReview.asset!,
      project!,
    );
    const productionCategory =
      productionCategories.indexOf(previousReview.productionCategory) >= 0
        ? previousReview.productionCategory
        : productionCategories[0];

    const costCategories = getUniqueCostCategories(
      previousReview.assetProjectTimeSeries,
      previousReview.asset!,
      project!,
    );

    const costCategory =
      productionCategories.indexOf(previousReview.costCategory) >= 0 ? previousReview.costCategory : costCategories[0];

    return {
      ...previousReview,
      project,
      productionCategories,
      productionCategory,
      costCategories,
      costCategory,
    };
  }

  if (action.type === ActionTypes.ReviewProductionCategoryChanged) {
    return {
      ...previousReview,
      productionCategory: action.data.productionCategory,
    };
  }

  if (action.type === ActionTypes.ReviewCostCategoryChanged) {
    return {
      ...previousReview,
      costCategory: action.data.costCategory,
    };
  }

  if (action.type === ActionTypes.ReviewTimingChanged) {
    return {
      ...previousReview,
      timing: action.data.timing,
    };
  }

  return {
    ...previousReview,
  };
};
