import * as React from 'react';

import { useStrategyState } from '../../state/StrategyState';
import { StrategoryChoicePermutations, StrategyChoiceDisplay } from '../../state/StrategyStateConstants';
import { useTradeOffRange } from '../../hooks/useTradeOffRange';

import { ChartColumn20 } from '@carbon/icons-react';
import lineIcon from '../../img/line.svg';

import {
  StrategyChoiceRoot,
  StrategyChoiceTitle,
  ChoiceHeader,
  ChoiceColumnHeader,
  ChoiceBody,
  ChoiceBodyCell,
  Choice,
  ChoiceBodyEmpty,
  unavailable,
  toggleIcon,
  ToggleIconLine,
  selected,
  highlight,
} from './StrategyChoiceStyles';
import { getChoiceTotals } from '../../state/StrategyStateHelpers';

const StrategyChoices = React.memo(() => {
  const [showTotals, setShowTotals] = React.useState<boolean>(false);
  const { strategy, strategyHighlightedChoiceChanged } = useStrategyState();
  const { isInRange } = useTradeOffRange();
  const { highlightedStrategyChoice, selectedStrategyId, strategies } = strategy;

  const choiceTotals = getChoiceTotals(strategy.filteredResultMetrics, isInRange);

  const handleToggleTotals = () => {
    setShowTotals(!showTotals);
  };

  const selectedStrategy = selectedStrategyId ? strategies[selectedStrategyId] : null;

  const handleMouseOver = (choiceKey: string, value: string) => () => {
    strategyHighlightedChoiceChanged({
      choiceKey,
      value,
    });
  };

  const handleMouseOut = () => {
    strategyHighlightedChoiceChanged(null);
  };

  return (
    <StrategyChoiceRoot>
      <StrategyChoiceTitle>Strategy choices</StrategyChoiceTitle>

      <ChoiceHeader>
        <ChoiceColumnHeader width={260}>Riser tower FID timing</ChoiceColumnHeader>
        <ChoiceColumnHeader width={260}>Riser tower base concept</ChoiceColumnHeader>
        <ChoiceColumnHeader width={280}>Optionality included in concept</ChoiceColumnHeader>
        <ChoiceColumnHeader width={260}>Opex rationalisation</ChoiceColumnHeader>
        <ChoiceColumnHeader width={260}>Commercial options</ChoiceColumnHeader>
        <ChoiceColumnHeader width={260}>F3-B electrification</ChoiceColumnHeader>

        <ChartColumn20 className={toggleIcon} onClick={handleToggleTotals} />
        {showTotals && (
          <ToggleIconLine onClick={handleToggleTotals}>
            <img src={lineIcon} />
          </ToggleIconLine>
        )}
      </ChoiceHeader>

      {[...Array(8)].map((item, index) => {
        return (
          <ChoiceBody key={index}>
            {StrategyChoiceDisplay.map((key: string) => {
              const choices = StrategoryChoicePermutations[key];

              if (choices.length <= index) {
                return (
                  <ChoiceBodyEmpty key={key} width={key === 'Riser tower - optionality' ? 280 : 260}>
                    <Choice>&nbsp;</Choice>
                  </ChoiceBodyEmpty>
                );
              }

              const available = !!choiceTotals[key][choices[index]].valid;

              const getBodyCellClass = () => {
                if (!available) {
                  return unavailable;
                }

                if (
                  highlightedStrategyChoice &&
                  highlightedStrategyChoice.choiceKey === key &&
                  highlightedStrategyChoice.value === choices[index]
                ) {
                  return highlight;
                }

                if (selectedStrategy && `${selectedStrategy[key]}` === `${choices[index]}`) {
                  return selected;
                }

                if (
                  highlightedStrategyChoice &&
                  highlightedStrategyChoice.choiceKey === key &&
                  highlightedStrategyChoice.value === StrategoryChoicePermutations[key]
                ) {
                  return selected;
                }

                return undefined;
              };

              const values = choiceTotals[key][choices[index]];
              const countWidth = (34 / values.total) * values.valid;

              return (
                <ChoiceBodyCell
                  width={key === 'Riser tower - optionality' ? 280 : 260}
                  key={key}
                  total={showTotals ? countWidth : undefined}
                  onMouseOver={handleMouseOver(key, choices[index])}
                  onMouseOut={handleMouseOut}
                >
                  <Choice className={getBodyCellClass()}>{choices[index]}</Choice>
                </ChoiceBodyCell>
              );
            })}
          </ChoiceBody>
        );
      })}
    </StrategyChoiceRoot>
  );
});

StrategyChoices.displayName = 'StrategyChoices';

export { StrategyChoices };
