import * as React from 'react';

import { fetchAssetProjectTimeSeriesData } from '../api/data-fetch';
import { useReviewState } from '../state/ReviewState';

const EXCLUDE_WORKIN_INTEREST = 'working interest';

export type UseFetchAssetProjectTimeSeriesData = {
  isFetching: boolean;
  fetchData: () => void;
  isError: boolean;
};

export const useFetchAssetProjectTimeSeriesData = (): UseFetchAssetProjectTimeSeriesData => {
  const { assetProjectTimeSeriesChanged } = useReviewState();
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [isError, setError] = React.useState<boolean>(false);

  const fetchData = async () => {
    try {
      setFetching(true);

      const response = await fetchAssetProjectTimeSeriesData();

      console.log('APTS', response.data);

      const zeroesRemoved = response.data.filter((serie) => {
        return Object.keys(serie.timeseries).filter((key) => serie.timeseries[key] > 0).length > 0;
      });

      assetProjectTimeSeriesChanged(zeroesRemoved.filter((item) => item.category !== EXCLUDE_WORKIN_INTEREST));
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    isFetching,
    fetchData,
    isError,
  };
};
