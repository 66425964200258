export const DiscountRatePercentages = [8, 10, 12];

/**
 * todo: Due to a bug in the TS compiler, the types file is not happy holding enums
 */
export enum PriceSettings {
  low = 1,
  medium = 2,
  high = 3,
}

export enum StrategyLevels {
  low = 'L',
  medium = 'M',
  high = 'H',
}

export enum NeptuneHighLevels {
  none = 'L',
  neptune = 'M',
  high = 'H',
}

export const StrategoryChoicePermutations = {
  "F17 - commercial": ["none", "Reduce F3-B share", "Increase F3-B share"],
  "F3-B electrification": ["no", "yes"],
  "OPEX rationalisation": ["none", "NOGAT spiking (no tanker)", "NOGAT spiking (no tanker & topsides)"],
  "Riser tower - base concept": [
    "none",
    "F5 only",
    "F17 only",
    "F6-IJssel only",
    "F5 + F17",
    "F5 + F6-IJssel",
    "F17 + F6-IJssel",
    "F5 + F17 + F6-IJssel"
  ],
  "Riser tower - optionality": ["none", "F4C-Deep", "Pollux & Castor", "F4C-Deep + Pollux & Castor"],
  "Riser tower - when to take FID": ["never", "2022"]
};

export const StrategyChoiceDisplay = [
  'Riser tower - when to take FID',
  'Riser tower - base concept',
  'Riser tower - optionality',
  'OPEX rationalisation',
  'F17 - commercial',
  'F3-B electrification',
];

export enum TimeSeriesAxisTypes {
  'area_cashflow' = 'area_cashflow',
  'area_unit_operating_cost' = 'area_unit_operating_cost',
  'F3-B_hub_production_&_processing' = 'F3-B_hub_production_&_processing',
  'F3-B_cashflow' = 'F3-B_cashflow',
  'F3-B_unit_operating_cost' = 'F3-B_unit_operating_cost',
}

export const TimeSeriesLabels = {
  area_cashflow: 'Area cashflow',
  area_unit_operating_cost: 'Area unit operating cost',
  'F3-B_hub_production_&_processing': 'F3-B hub production & processing',
  'F3-B_cashflow': 'F3-B cashflow',
  'F3-B_unit_operating_cost': 'F3-B unit operating cost',
};

export const TimeSeriesAxisLabels = {
  area_cashflow: 'Area cashflow [MEUR]',
  area_unit_operating_cost: 'Area unit operating cost [EUR/boe]',
  'F3-B_hub_production_&_processing': 'F3-B hub production & processing [kboe/d]',
  'F3-B_cashflow': 'F3-B cashflow [MEUR]',
  'F3-B_unit_operating_cost': 'F3-B unit operating cost [EUR/boe]',
};

export enum StrategySliderTypes {
  single,
  dual,
}

export enum ESGAxisTypes {
  CoP_year = 'CoP_year',
  CO2_emissions = 'CO2_emissions',
}

export const ESGLabels = {
  CoP_year: 'CoP year',
  CO2_emissions: 'CO2 emissions',
};

export enum StrategyToggleBool {
  ON = 'ON',
  OFF = 'OFF',
}
