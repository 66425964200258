import { Action, ActionTypes } from '../context/ApplicationContextTypes';
import { StrategyState } from './StrategyStateTypes';
import { findScenarioId } from './StrategyStateHelpers';
import { StrategyLevels } from './StrategyStateConstants';

export const strategyReducer = (previousStrategy: StrategyState, action: Action): StrategyState => {
  if (action.type === ActionTypes.StrategySelectedChanged) {
    return {
      ...previousStrategy,
      selectedStrategyId: action.data.strategyId,
    };
  }

  if (action.type === ActionTypes.StrategyTimeSeriesAxisChanged) {
    return {
      ...previousStrategy,
      timeSeriesAxis: action.data.timeSeriesAxis,
    };
  }

  if (action.type === ActionTypes.StrategyComplexitiesFetched) {
    return {
      ...previousStrategy,
      complexities: action.data.complexities,
    };
  }

  if (action.type === ActionTypes.StrategyParameterSettingsChanged) {
    return {
      ...previousStrategy,
      selectedScenarioId: action.data.selectedScenarioId,
    };
  }

  if (action.type === ActionTypes.StrategyEconomicResultsFetched) {
    return {
      ...previousStrategy,
      filteredResultMetrics: action.data.filteredResultMetrics,
      filteredResultTradeOff: action.data.filteredResultTradeOff,
      filteredResultTrigger: !previousStrategy.filteredResultTrigger,
    };
  }

  if (action.type === ActionTypes.StrategyEconomicLevelChanged) {
    return {
      ...previousStrategy,
      parameterTrigger: !previousStrategy.parameterTrigger,
      economicSettings: {
        ...previousStrategy.economicSettings,
        [action.data.name]: {
          ...previousStrategy.economicSettings[action.data.name],
          value: action.data.level,
        },
      },
    };
  }

  if (action.type === ActionTypes.StrategyMappingFetched) {
    return {
      ...previousStrategy,
      strategies: action.data.strategies,
    };
  }

  if (action.type === ActionTypes.StrategyScenarioMappingFetched) {
    return {
      ...previousStrategy,
      scenarios: action.data.scenarios,
      selectedScenarioId: findScenarioId(
        action.data.scenarios,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        StrategyLevels.medium,
        true,
        true,
        true,
        true,
        true,
        true,
      ),
    };
  }

  if (action.type === ActionTypes.StrategyHighlightedChoiceChanged) {
    return {
      ...previousStrategy,
      highlightedStrategyChoice: action.data.choice,
    };
  }

  if (action.type === ActionTypes.StrategyGeneralToggleChanged) {
    const toggle = previousStrategy.generalSettingsToggles[action.data.name];

    const result = {
      ...previousStrategy,
      generalSettingsToggles: {
        ...previousStrategy.generalSettingsToggles,
        [action.data.name]: {
          ...toggle,
          status: action.data.status,
        },
      },
    };

    return {
      ...result,
    };
  }

  if (action.type === ActionTypes.StrategyF3ToggleChanged) {
    const toggle = previousStrategy.f3SettingsToggles[action.data.name];

    return {
      ...previousStrategy,
      f3SettingsToggles: {
        ...previousStrategy.f3SettingsToggles,
        [action.data.name]: {
          ...toggle,
          status: action.data.status,
        },
      },
    };
  }

  if (action.type === ActionTypes.StrategyScenarioSliderChanged) {
    const slider = previousStrategy.scenarioSliders[action.data.name];

    return {
      ...previousStrategy,
      parameterTrigger: !previousStrategy.parameterTrigger,
      scenarioSliders: {
        ...previousStrategy.scenarioSliders,
        [action.data.name]: {
          ...slider,
          minValue: action.data.maxValue !== null ? action.data.minValue : undefined,
          maxValue: action.data.maxValue !== null ? action.data.maxValue : undefined,
          value: action.data.maxValue === null ? action.data.minValue : undefined,
        },
      },
    };
  }

  if (action.type === ActionTypes.StrategyDiscountRateChanged) {
    return {
      ...previousStrategy,
      parameterTrigger: !previousStrategy.parameterTrigger,
      economicSettings: {
        ...previousStrategy.economicSettings,
        discountRate: {
          ...previousStrategy.economicSettings.discountRate,
          value: action.data.rate,
        },
      },
    };
  }

  if (action.type === ActionTypes.StrategyNeptuneLevelChanged) {
    return {
      ...previousStrategy,
      parameterTrigger: !previousStrategy.parameterTrigger,
      economicSettings: {
        ...previousStrategy.economicSettings,
        [action.data.name]: {
          ...previousStrategy.economicSettings[action.data.name],
          value: action.data.value,
        },
      },
    };
  }

  if (action.type === ActionTypes.StrategyEconomicTradeOffsRangeChanged) {
    return {
      ...previousStrategy,
      economicSettings: {
        ...previousStrategy.economicSettings,
        tradeOffRange: {
          ...action.data.range,
        },
      },
    };
  }

  return {
    ...previousStrategy,
  };
};
