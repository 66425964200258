import { ActionTypes } from '../context/ApplicationContextTypes';
import { useApplicationContext } from '../context/ApplicationContext';
import { LoginStatus } from './SessionStateTypes';

export const useSessionState = () => {
  const { dispatch, state } = useApplicationContext();

  const setLoginStatus = (status: LoginStatus) => {
    dispatch({
      type: ActionTypes.SetLoginStatus,
      data: status,
    });
  };

  const logout = (): void => {
    localStorage.removeItem('token');
    setLoginStatus(LoginStatus.LoggedOff);
  };

  return {
    session: state!.session,
    setLoginStatus,
    logout,
  };
};
