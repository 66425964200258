import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressStep as CarbonProgressStep } from 'carbon-components-react';
import { RenderLabelProps } from 'carbon-components-react/lib/components/ProgressIndicator/ProgressIndicator';

interface ProgressStepProps {
  href: string;
  label: string;
  disabled: boolean;
  current: boolean;
  complete?: boolean;
}

const ProgressStep = React.memo<ProgressStepProps>(({ href, label, disabled, current, complete }) => {
  const history = useHistory();

  const renderLabel: React.FC<RenderLabelProps> = ({ children }) => (
    <span className="bx--progress-label ovr-flow-nav-label">{children}</span>
  );

  return (
    <CarbonProgressStep
      onClick={() => {
        history.push(href);
      }}
      label={label}
      renderLabel={renderLabel}
      disabled={disabled}
      current={current}
      complete={complete}
    />
  );
});

ProgressStep.displayName = 'ProgressStep';

export default ProgressStep;
