import { useApplicationContext } from '../context/ApplicationContext';
import { ActionTypes } from '../context/ApplicationContextTypes';
import { AssetProjectTimeSeries, TimingTypes } from './ReviewStateTypes';

export interface ReviewAssetChanged {
  type: ActionTypes.ReviewAssetChanged;
  data: {
    asset: string | null;
  };
}

export interface ReviewProjectChanged {
  type: ActionTypes.ReviewProjectChanged;
  data: {
    project: string | null;
  };
}

export interface ReviewProductionCategoryChanged {
  type: ActionTypes.ReviewProductionCategoryChanged;
  data: {
    productionCategory: string;
  };
}

export interface ReviewCostCategoryChanged {
  type: ActionTypes.ReviewCostCategoryChanged;
  data: {
    costCategory: string;
  };
}

export interface ReviewTimingChanged {
  type: ActionTypes.ReviewTimingChanged;
  data: {
    timing: TimingTypes;
  };
}

export interface ReviewAssetProjectTimeSeriesChanged {
  type: ActionTypes.ReviewAssetProjectTimeSeriesChanged;
  data: {
    series: AssetProjectTimeSeries[];
  };
}

export const useReviewState = () => {
  const { dispatch, state } = useApplicationContext();

  const assetChanged = (asset: string | null) => {
    dispatch({
      type: ActionTypes.ReviewAssetChanged,
      data: {
        asset,
      },
    });
  };

  const projectChanged = (project: string | null) => {
    dispatch({
      type: ActionTypes.ReviewProjectChanged,
      data: {
        project,
      },
    });
  };

  const productionCategoryChanged = (productionCategory: string) => {
    dispatch({
      type: ActionTypes.ReviewProductionCategoryChanged,
      data: {
        productionCategory,
      },
    });
  };

  const costCategoryChanged = (costCategory: string) => {
    dispatch({
      type: ActionTypes.ReviewCostCategoryChanged,
      data: {
        costCategory,
      },
    });
  };

  const timingChanged = (timing: TimingTypes) => {
    dispatch({
      type: ActionTypes.ReviewTimingChanged,
      data: {
        timing,
      },
    });
  };

  const assetProjectTimeSeriesChanged = (series: AssetProjectTimeSeries[]) => {
    dispatch({
      type: ActionTypes.ReviewAssetProjectTimeSeriesChanged,
      data: {
        series,
      },
    });
  };

  return {
    review: state!.review,
    assetChanged,
    productionCategoryChanged,
    projectChanged,
    costCategoryChanged,
    timingChanged,
    assetProjectTimeSeriesChanged,
  };
};
