import React from 'react';
import { ToastNotification } from 'carbon-components-react';

import { useNotificationState } from '../../state/NotificationState';
import { NotificationType } from '../../state/NotificationStateTypes';

import * as css from './NotificationStyles';

const Notification = React.memo(() => {
  const { notifications, notificationTimeout } = useNotificationState();
  const timer = React.useRef<number | null>(null);

  const { title, caption, notificationIndex, notificationType } = notifications;

  React.useEffect(() => {
    if (!notificationIndex) {
      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    const newTimer = window.setTimeout(() => {
      notificationTimeout();
      timer.current = null;
    }, 5000);

    timer.current = newTimer;

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [notificationIndex]);

  const derivedTitle = title || (notificationType === NotificationType.success ? 'Success' : 'Error');
  const kind = notificationType === NotificationType.success ? 'success' : 'error';

  return (
    <>
      {notificationType !== NotificationType.disabled && (
        <ToastNotification
          className={css.toastNotificationClass}
          title={derivedTitle}
          kind={kind}
          hideCloseButton
          caption={caption}
        />
      )}
    </>
  );
});

export { Notification };
