import * as React from 'react';

import { ChartTitle } from '../../../atoms/CharTitle';
import { NonTechnicalRiskChart } from './molecules/NonTechnicalRiskChart';

import { NonTechnicalRiskChartContainerRoot } from './NonTechnicalRiskChartContainerStyles';

const NonTechnicalRiskChartContainer = React.memo(() => {
  return (
    <NonTechnicalRiskChartContainerRoot>
      <ChartTitle title="Non technical risk" marginLeft="100px" marginBottom="24px" />
      <NonTechnicalRiskChart />
    </NonTechnicalRiskChartContainerRoot>
  );
});

NonTechnicalRiskChartContainer.displayName = 'NonTechnicalRiskChartContainer';

export { NonTechnicalRiskChartContainer };
