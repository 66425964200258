import * as React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '../../context/ThemeContext';

export interface TitleProps {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
}

export const Title = styled.div<TitleProps>`
  height: 36px;
  margin-top: ${(props) => props.marginTop || props.theme.spacing05};
  margin-left: ${(props) => props.marginLeft || '40px'};
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #161616;
  margin-bottom: ${(props) => props.marginBottom || props.theme.spacing07};
`;

export interface CharTitleProps {
  title: React.ReactNode;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
}

const ChartTitle = React.memo<CharTitleProps>((props) => {
  const theme = useTheme();
  const { title, marginTop = theme.spacing05, marginBottom = theme.spacing07, marginLeft = '40px' } = props;

  return (
    <Title marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft}>
      {title}
    </Title>
  );
});

ChartTitle.displayName = 'ChartTitle';

export { ChartTitle };
