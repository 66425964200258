import styled from '@emotion/styled';
import { css } from '@emotion/css';

export const ProductionTimeSeriesRoot = styled.div`
  margin-left: 0px;
`;

export const legend = css`
  position: absolute;
  top: 0;
  right: 0;
`;
