import * as React from 'react';
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  AreaSeries,
  LineSeries,
  LineSeriesCanvas,
  AreaSeriesPoint,
  LineSeriesPoint,
} from 'react-vis';

import { useStrategyState } from '../../../../../state/StrategyState';
import { EconomicResultMetrics } from '../../../../../state/StrategyStateTypes';
import { useTradeOffRange } from '../../../../../hooks/useTradeOffRange';
import {
  TimeSeriesAxisLabels,
  TimeSeriesAxisTypes,
  TimeSeriesLabels,
} from '../../../../../state/StrategyStateConstants';

import { TimeSeriesChartRoot } from './TimeSeriesChartStyles';

const YEAR_OFFSET = new Date().getFullYear();
const YEAR_END = 2060;
const YEAR_LENGTH = YEAR_END - YEAR_OFFSET + 1;

const getSeries = (
  metrics: EconomicResultMetrics[],
  timeSeriesAxis: TimeSeriesAxisTypes,
  isInRange: (metric: EconomicResultMetrics) => boolean,
) => {
  const validMetrics = metrics.filter((metric) => isInRange(metric));
  const invalidMetrics = metrics.filter((metric) => !isInRange(metric));

  const years = [...Array(YEAR_LENGTH)].map((item, index) => index + YEAR_OFFSET);

  const invalidSeries = invalidMetrics.length > 0
    ? years.map((year) => {
      const result = invalidMetrics.reduce(
        (accumulator, nextMetric) => ({
          x: year,
          y: Math.max(accumulator.y, nextMetric[timeSeriesAxis][year]),
          y0: Math.min(accumulator.y0, nextMetric[timeSeriesAxis][year]),
        }),
        { x: 0, y: 0, y0: Infinity },
      );

      return result;
    })
    : null;

  const validSeries = validMetrics.length > 0
    ? years.map((year) => {
      const result = validMetrics.reduce(
        (accumulator, nextMetric) => ({
          x: year,
          y: Math.max(accumulator.y, nextMetric[timeSeriesAxis][year]),
          y0: Math.min(accumulator.y0, nextMetric[timeSeriesAxis][year]),
        }),
        { x: 0, y: 0, y0: Infinity },
      );

      return result;
    })
    : null;

  return {
    invalidSeries,
    validSeries,
  };
};

const getLineSeries = (years: { [key: string]: number }): LineSeriesPoint[] => {
  const keys = Object.keys(years);

  return keys
    .map((key) => ({
      x: parseInt(key, 10),
      y: years[key],
    }))
    .slice(0, YEAR_LENGTH);
};

const TimeSeriesChart = React.memo((props) => {
  const handleTickFormat = (value: string) => value;
  const { isInRange } = useTradeOffRange();

  const { strategy } = useStrategyState();
  const {
    highlightedStrategyChoice,
    filteredResultMetrics,
    filteredResultTrigger,
    selectedStrategyId,
    economicResults,
    selectedScenarioId,
    economicSettings,
    timeSeriesAxis,
  } = strategy;

  const { validSeries, invalidSeries } = React.useMemo(
    () => getSeries(filteredResultMetrics, timeSeriesAxis, isInRange),
    [filteredResultTrigger, timeSeriesAxis, economicSettings.tradeOffRange.x, economicSettings.tradeOffRange.y],
  );

  const getSelectedStrategyData = () => {
    if (!selectedStrategyId) {
      return null;
    }

    const metric = filteredResultMetrics.find((findMetric) => findMetric.strategyId === selectedStrategyId);

    if (!metric) {
      return null;
    }

    return getLineSeries(metric[timeSeriesAxis]);
  };

  const selectedStrategyData = getSelectedStrategyData();

  const highlights = highlightedStrategyChoice
    ? filteredResultMetrics
      .filter(
        (metric) =>
          `${metric.strategy![highlightedStrategyChoice.choiceKey]}` === `${highlightedStrategyChoice.value}`,
      )
      .map((metric) => getLineSeries(metric[timeSeriesAxis]))
    : null;

  return (
    <TimeSeriesChartRoot>
      <XYPlot width={480} height={200} margin={{ top: 18 }}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickFormat={handleTickFormat} title="Year" />
        <YAxis
          title={TimeSeriesAxisLabels[timeSeriesAxis]}
          style={{ title: { textAnchor: 'start', transform: 'translate(6px,-4px) rotate(0deg)' } }}
        />

        {invalidSeries && <AreaSeries color="#aeaeae" opacity={0.5} data={invalidSeries} />}
        {validSeries && <AreaSeries color="#9e9e9e" opacity={1} data={validSeries} />}

        {selectedStrategyData && (
          <LineSeriesCanvas
            color="#B3DEE0"
            colorType="literal"
            className="area-elevated-line-series"
            data={selectedStrategyData}
          />
        )}

        {highlights
          && highlights.map((item, index) => (
            <LineSeriesCanvas
              key={index}
              color="#097b90"
              colorType="literal"
              className="area-elevated-line-series"
              data={item}
              strokeWidth={1}
            />
          ))}
      </XYPlot>
    </TimeSeriesChartRoot>
  );
});

TimeSeriesChart.displayName = 'TimeSeriesChart';

export { TimeSeriesChart };
