import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import usePrevious from 'use-previous';

import { useWindowDimensions } from '../../hooks/Dimensions';
import { SmallWindow } from './SmallWindow';
import { Notification } from '../molecules/Notification';
import { ReviewInput } from '../../features/review-input/ReviewInput';
import { AnalyseConcepts } from '../../features/analyse-concepts/AnalyseConcepts';
import { LandingPage } from '../../features/landing-page/LandingPage';
import { useFetchAssetProjectTimeSeriesData } from '../../hooks/useFetchAssetProjectTimeSeriesData';
import { useFetchStrategyMapping } from '../../hooks/useFetchStrategyMapping';
import { useFetchScenarioMapping } from '../../hooks/useFetchScenarioMapping';
import { useFetchEconomicResults } from '../../hooks/useFetchEconomicResults';
import { useStrategyState } from '../../state/StrategyState';
import { useFetchComplexities } from '../../hooks/useFetchComplexity';
import { convertDiscount, convertNeptuneLevels, convertSlider, findScenarioId } from '../../state/StrategyStateHelpers';
import { StrategyLevels } from '../../state/StrategyStateConstants';

import '../../scss/app.scss';

const MIN_WINDOW_WIDTH = 1200;

const App: React.FC = (): React.ReactElement => {
  const { width: currentWidth } = useWindowDimensions();
  const smallWindow = currentWidth < MIN_WINDOW_WIDTH;

  const { fetchData: fetchStrategyData, isFetching: isFetchingStrategies } = useFetchStrategyMapping();
  const { fetchData: fetchScenarioData, isFetching: isFetchingScenarios } = useFetchScenarioMapping();
  const { fetchData: fetchEconomicResults, isFetching: isFetchingEconomicResults } = useFetchEconomicResults();
  const { fetchData: fetchComplexities, isFetching: isFetchingComplexities } = useFetchComplexities();
  const { fetchData: fetchAssetData, isFetching: isFetchingAssetData } = useFetchAssetProjectTimeSeriesData();
  const {
    strategy,
    parameterSettingsChanged,
    strategyEconomicResultsFetched,
    strategySelectedChanged,
  } = useStrategyState();
  const { selectedScenarioId, filteredResultMetrics, economicResults, strategies, scenarios } = strategy;

  React.useEffect(() => {
    fetchAssetData();
    fetchStrategyData();
    fetchScenarioData();
    fetchComplexities();
  }, []);

  const wasFetchingStrategies = usePrevious(isFetchingStrategies);

  React.useEffect(() => {
    if (wasFetchingStrategies && !isFetchingStrategies) {
      fetchEconomicResults(selectedScenarioId);
    }
  }, [isFetchingStrategies]);

  React.useEffect(() => {
    if (!selectedScenarioId || Object.keys(strategies).length === 0) {
      strategyEconomicResultsFetched([], []);
      return;
    }

    fetchEconomicResults(selectedScenarioId);
  }, [selectedScenarioId]);

  React.useEffect(() => {
    if (Object.keys(strategy.scenarios).length === 0) {
      return;
    }

    strategySelectedChanged('');

    const selectedScenarioId = findScenarioId(
      strategy.scenarios,
      strategy.economicSettings.hydrocarbonPrice.value,
      StrategyLevels.medium,
      convertDiscount(strategy.economicSettings.discountRate.value),
      StrategyLevels.medium,
      convertSlider(strategy.scenarioSliders.f17FIDTiming.value),
      convertNeptuneLevels(strategy.economicSettings.co2Tax.value),
      convertSlider(strategy.scenarioSliders.riseTowerIncrementalProcessingTariff.value),
      convertSlider(strategy.scenarioSliders.reservoirOutcome.value),
      convertSlider(strategy.scenarioSliders.operatingCost.value),
      convertSlider(strategy.scenarioSliders.capitalExpenditure.value),
      strategy.f3SettingsToggles.f5ABOverF3B.status,
      strategy.f3SettingsToggles.f17OverF3B.status,
      strategy.f3SettingsToggles.f6IJsselOverF3B.status,
      strategy.f3SettingsToggles.polluxCastorOverF3B.status,
      strategy.f3SettingsToggles.f4CShallowOverF3B.status,
      strategy.f3SettingsToggles.f4CDeepOverF3B.status,
    );

    if (!selectedScenarioId) {
      console.warn('Scenario not found');
      return;
    }

    parameterSettingsChanged(selectedScenarioId);
  }, [strategy.parameterTrigger, strategy.f3SettingsToggles]);

  if (
    filteredResultMetrics.length === 0 ||
    isFetchingComplexities ||
    isFetchingScenarios ||
    isFetchingStrategies ||
    !strategy ||
    isFetchingAssetData
  ) {
    return <div></div>;
  }

  return (
    <>
      {/*{isLoading && <LoadingBar percentage={percentage} />}*/}
      {smallWindow ? (
        <SmallWindow minimumWidth={MIN_WINDOW_WIDTH} currentWidth={currentWidth} />
      ) : (
        <>
          <Switch>
            <Route exact path="/review-input" component={ReviewInput} />
            <Route exact path="/analyse-concepts" component={AnalyseConcepts} />
            <Route component={LandingPage} />(
          </Switch>
          <Notification />
        </>
      )}
    </>
  );
};

export { App };
