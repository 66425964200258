// @ts-ignore
import { white, g100 } from '@carbon/themes';

// https://github.com/carbon-design-system/carbon/blob/master/packages/themes/src/g100.js
export interface CarbonTheme {
  interactive01: string;
  interactive02: string;
  interactive03: string;
  interactive04: string; // #4589ff

  uiBackground: string; // #161616

  ui01: string; // #262626
  ui02: string; // #393939
  ui03: string; // #393939
  ui04: string; // #6f6f6f
  ui05: string; // #f4f4f4

  text01: string; // #f4f4f4
  text02: string; // #c6c6c6
  text03: string;
  text04: string;
  text05: string;
  textError: string;

  icon01: string;
  icon02: string;
  icon03: string;

  link01: string;
  inverseLink: string;

  field01: string; // #262626
  field02: string;

  inverse01: string;
  inverse02: string;

  support01: string;
  support02: string;
  support03: string;
  support04: string;

  inverseSupport01: string;
  inverseSupport02: string;
  inverseSupport03: string;
  inverseSupport04: string;

  overlay01: string;

  danger01: string;
  danger02: string;

  // Interaction states
  focus: string;
  inverseFocusUi: string;

  hoverPrimary: string;
  activePrimary: string;

  hoverPrimaryText: string;

  hoverSecondary: string;
  activeSecondary: string;

  hoverTertiary: string;
  activeTertiary: string;
  hoverUI: string;
  hoverLightUI: string;

  activeUI: string;
  activeLightUI: string;
  selectedUI: string;
  selectedLightUI: string;
  inverseHoverUI: string; // #e5e5e5

  hoverSelectedUI: string;

  hoverDanger: string;
  activeDanger: string;

  hoverRow: string;

  visitedLink: string;

  disabled01: string;
  disabled02: string;
  disabled03: string;

  highlight: string;

  decorative01: string;

  buttonSeparator: string;

  skeleton01: string;
  skeleton02: string;

  // Type
  caption01: string;
  label01: string;
  helperText01: string;
  bodyShort01: string;
  bodyLong01: string;
  bodyShort02: string;
  bodyLong02: string;
  code01: string;
  code02: string;
  heading01: string;
  productiveHeading01: string;
  heading02: string;
  productiveHeading02: string;
  productiveHeading03: string;
  productiveHeading04: string;
  productiveHeading05: string;
  productiveHeading06: string;
  productiveHeading07: string;
  expressiveHeading01: string;
  expressiveHeading02: string;
  expressiveHeading03: string;
  expressiveHeading04: string;
  expressiveHeading05: string;
  expressiveHeading06: string;
  expressiveParagraph01: string;
  quotation01: string;
  quotation02: string;
  display01: string;
  display02: string;
  display03: string;
  display04: string;

  // Layout
  // Spacing

  ///
  spacing01: string; // 0.125rem 2px
  spacing02: string; // 0.25rem  4px
  spacing03: string; // 0.5rem   8px
  spacing04: string; // 0.75rem 12px
  spacing05: string; // 1rem    16px
  spacing06: string; // 1.5rem  24px
  spacing07: string; // 2rem    32px
  spacing08: string; // 2.5rem  40px
  spacing09: string; // 3rem    49px
  spacing10: string;
  spacing11: string;
  spacing12: string;

  // Fluid spacing
  fluidSpacing01: string;
  fluidSpacing02: string;
  fluidSpacing03: string;
  fluidSpacing04: string;

  // Layout
  layout01: string;
  layout02: string;
  layout03: string;
  layout04: string;
  layout05: string;
  layout06: string;
  layout07: string;

  // Containers
  container01: string;
  container02: string;
  container03: string;
  container04: string;
  container05: string;

  // Icon sizes
  iconSize01: string;
  iconSize02: string;
}

export default g100 as CarbonTheme;

const lightTheme = white as CarbonTheme;
export { lightTheme };
