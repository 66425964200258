import * as React from 'react';
import {AreaSeries, AreaSeriesPoint, XAxis, XYPlot, YAxis} from 'react-vis';
import {curveCatmullRom} from 'd3-shape';

import {useStrategyState} from '../../../../../state/StrategyState';
import {useTradeOffRange} from '../../../../../hooks/useTradeOffRange';
import {ESGAxisTypes} from '../../../../../state/StrategyStateConstants';
import {EconomicResultMetrics} from '../../../../../state/StrategyStateTypes';

import {ESGChartRoot} from './ESGCharStyles';

const YEAR_OFFSET = 2021;
const YEAR_LENGTH = 40;

export interface ESGChartProps {
  axis: ESGAxisTypes;
}

const ESGChart = React.memo<ESGChartProps>((props) => {
  const { strategy } = useStrategyState();
  const { axis } = props;
  const {
    filteredResultTrigger,
    filteredResultMetrics,
    economicSettings,
    selectedStrategyId,
    highlightedStrategyChoice,
  } = strategy;
  const { isInRange } = useTradeOffRange();

  const sortedMetrics = filteredResultMetrics.sort((a, b) => {
    if (axis === ESGAxisTypes.CO2_emissions) {
      return a.average_carbon_emission - b.average_carbon_emission;
    }

    return a.CoP_year - b.CoP_year;
  });

  const getSeriesPoint = (
    lastValid: boolean,
    currentValid: boolean,
    accumulator: AreaSeriesPoint[][],
    nextMetric: EconomicResultMetrics,
    index: number,
  ): AreaSeriesPoint[][] => {
    if (!currentValid) {
      return accumulator;
    }

    if (lastValid && currentValid) {
      return [
        ...accumulator.slice(0, accumulator.length - 1),
        [
          ...accumulator[accumulator.length - 1],
          {
            x: index,
            y:
              axis === ESGAxisTypes.CO2_emissions
                ? nextMetric.average_carbon_emission
                : YEAR_OFFSET + nextMetric.CoP_year,
            y0: axis === ESGAxisTypes.CO2_emissions ? 0 : YEAR_OFFSET,
          },
        ],
      ];
    }

    return [
      ...accumulator,
      [
        {
          x: index,
          y:
            axis === ESGAxisTypes.CO2_emissions
              ? nextMetric.average_carbon_emission
              : YEAR_OFFSET + nextMetric.CoP_year,
          y0: axis === ESGAxisTypes.CO2_emissions ? 0 : YEAR_OFFSET,
        },
      ],
    ];
  };

  const validData = React.useMemo(() => {
    return sortedMetrics.reduce<AreaSeriesPoint[][]>((accumulator, nextMetric, index) => {
      const lastValid = index > 0 && isInRange(sortedMetrics[index - 1]);
      const currentValid = isInRange(nextMetric);

      return getSeriesPoint(lastValid, currentValid, accumulator, nextMetric, index);
    }, []);
  }, [filteredResultTrigger, economicSettings.tradeOffRange, axis]);

  const invalidData = React.useMemo(() => {
    return sortedMetrics.reduce<AreaSeriesPoint[][]>((accumulator, nextMetric, index) => {
      const lastValid = index > 0 && !isInRange(sortedMetrics[index - 1]);
      const currentValid = !isInRange(nextMetric);

      return getSeriesPoint(lastValid, currentValid, accumulator, nextMetric, index);
    }, []);
  }, [filteredResultTrigger, economicSettings.tradeOffRange, axis]);

  const selectedSerie = React.useMemo(() => {
    if (!selectedStrategyId) {
      return [];
    }

    const index = sortedMetrics.findIndex((metric) => metric.strategyId === selectedStrategyId);

    return [
      {
        x: index,
        y:
          axis === ESGAxisTypes.CO2_emissions
            ? sortedMetrics[index].average_carbon_emission
            : YEAR_OFFSET + sortedMetrics[index].CoP_year,
        y0: axis === ESGAxisTypes.CO2_emissions ? 0 : YEAR_OFFSET,
      },
    ];
  }, [filteredResultTrigger, economicSettings.tradeOffRange, selectedStrategyId, axis]);

  const highlightSeries = React.useMemo<AreaSeriesPoint[][]>(() => {
    if (!highlightedStrategyChoice) {
      return [];
    }

    return sortedMetrics.reduce<AreaSeriesPoint[][]>((accumulator, nextMetric, index) => {
      const lastValid =
        index > 0 &&
        `${sortedMetrics[index - 1].strategy![highlightedStrategyChoice.choiceKey]}` ===
          `${highlightedStrategyChoice.value}`;
      const currentValid =
        `${nextMetric.strategy![highlightedStrategyChoice.choiceKey]}` === `${highlightedStrategyChoice.value}`;

      return getSeriesPoint(lastValid, currentValid, accumulator, nextMetric, index);
    }, []);
  }, [filteredResultTrigger, economicSettings.tradeOffRange, highlightedStrategyChoice, axis]);

  const formatYAxis = (value: number, index: number) => {
    if (index % 2 === 1) {
      return '';
    }

    return `${value}`;
  };

  return (
    <ESGChartRoot>
      <XYPlot width={480} height={100} margin={{ left: 50, top: 18 }}>
        <XAxis title="# of strategies" style={{ title: { textAnchor: 'end', transform: 'translate(208px, -25px)' } }} />
        <YAxis
          title={axis === ESGAxisTypes.CoP_year ? 'Year' : 'tonnes/yr'}
          style={{ title: { textAnchor: 'start', transform: 'translate(6px, -4px) rotate(0deg)' } }}
          tickFormat={formatYAxis as any}
        />
        {invalidData.map((invalidDataItem, index) => {
          return (
            <AreaSeries
              key={index}
              colorType="literal"
              color="#aeaeae"
              opacity={0.5}
              data={invalidDataItem}
              //getOpacity={(value) => 0.1} //(value.validItems === 0 ? 0.2 : 0.8)}
              curve={curveCatmullRom.alpha(0.5)}
            />
          );
        })}

        {validData.map((validDataItem, index) => {
          return (
            <AreaSeries
              key={index}
              colorType="literal"
              color="#9e9e9e"
              opacity={1}
              data={validDataItem}
              //getOpacity={(value) => 0.1} //(value.validItems === 0 ? 0.2 : 0.8)}
              curve={curveCatmullRom.alpha(0.5)}
            />
          );
        })}

        <AreaSeries
          colorType="literal"
          color="#B3DEE0"
          opacity={1}
          data={selectedSerie}
          //getOpacity={(value) => 0.1} //(value.validItems === 0 ? 0.2 : 0.8)}
        />

        {highlightSeries.map((highlight, index) => (
          <AreaSeries
            key={index}
            colorType="literal"
            color="#097B90"
            opacity={1}
            data={highlight}
            //getOpacity={(value) => 0.1} //(value.validItems === 0 ? 0.2 : 0.8)}
          />
        ))}
      </XYPlot>
    </ESGChartRoot>
  );
});

ESGChart.displayName = 'ESGChart';

export { ESGChart };
