import * as React from 'react';

import { useStrategyState } from '../state/StrategyState';
import { fetchComplexities } from '../api/data-fetch';

export interface UseFetchComplexities {
  isFetching: boolean;
  fetchData: () => void;
  isError: boolean;
}

export const useFetchComplexities = (): UseFetchComplexities => {
  const { complexitiesFetched } = useStrategyState();
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [isError, setError] = React.useState<boolean>(false);

  const fetchData = async () => {
    try {
      setFetching(true);

      const response = await fetchComplexities();

      console.log('Complexities', response.data);

      complexitiesFetched(response.data);
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    isFetching,
    fetchData,
    isError,
  };
};
