import { getReactAppAPIURL } from '../utils/getWindowConfigOption';
import axios, {AxiosResponse} from 'axios';
import {ScenarioEconomicResultMetrics, StrategyEconomicResultMetrics} from "../state/StrategyStateTypes";

export const fetchScenarioResult = (scenarioId: string): Promise<AxiosResponse<StrategyEconomicResultMetrics>> => {
  const url = `${getReactAppAPIURL()}/results?file_id=${scenarioId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axios.get(url, config);
};
