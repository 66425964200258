import { SessionState } from './SessionStateTypes';
import { Action, ActionTypes } from '../context/ApplicationContextTypes';

export const sessionReducer = (previousSession: SessionState, action: Action): SessionState => {
  if (action.type === ActionTypes.SetLoginStatus) {
    return {
      ...previousSession,
      loginStatus: action.data,
    };
  }

  return {
    ...previousSession,
  };
};
