import * as React from 'react';

import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';
import { PageContent } from '../../components/molecules/PageContent';
import { useTheme } from '../../context/ThemeContext';
import { ReviewInputSettings } from '../../components/organisms/ReviewInputSettings';
import { ReviewCharts } from '../../components/templates/ReviewCharts';

import * as css from './ReviewInputStyles';

const ReviewInput: React.FC = (): JSX.Element => {
  const theme = useTheme();

  return (
    <div>
      <AppHeader />
      <FlowNavigation />
      <PageContent className={css.columns(theme)}>
        <ReviewInputSettings />
        <ReviewCharts />
      </PageContent>
    </div>
  );
};

export { ReviewInput };
