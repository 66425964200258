import * as React from 'react';

import { ChartTitle } from '../../../atoms/CharTitle';

import { AssetMapContainerRoot } from './AssetMapContainerStyles';
import { AssetMap } from './molecules';

const AssetMapContainer = React.memo(() => {
  return (
    <AssetMapContainerRoot>
      <ChartTitle title="Asset map" />
      <AssetMap />
    </AssetMapContainerRoot>
  );
});

AssetMapContainer.displayName = 'AssetMapContainer';

export { AssetMapContainer };
