import * as React from 'react';

import { ChartTitle } from '../../../atoms/CharTitle';
import { CostTimeSeries } from './molecules';

import { CostTimeSeriesContainerRoot } from './CostTimeSeriesContainerStyles';

const CostTimeSeriesContainer = React.memo(() => {
  return (
    <CostTimeSeriesContainerRoot>
      <ChartTitle title="Cost time series" />
      <CostTimeSeries />
    </CostTimeSeriesContainerRoot>
  );
});

CostTimeSeriesContainer.displayName = 'CostTimeSeriesContainer';

export { CostTimeSeriesContainer };
