import axios, {AxiosError, AxiosResponse} from 'axios';

import { getReactAppAPIURL } from '../../utils/getWindowConfigOption';
import { User } from './Login';
import * as React from 'react';

interface MakeLoginRequest {
  (
    user: User,
    loggedIn: (isLoggedIn: boolean) => void,
    setError: React.Dispatch<React.SetStateAction<string | null | undefined | string[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ): void;
}

const makeLoginRequest: MakeLoginRequest = (user, loggedIn, setError, setLoading) => {
  const url = `${getReactAppAPIURL()}/login`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const data = new FormData();

  data.append('username', user.username);
  data.append('password', user.password);

  axios
    .post(url, data, config)
    .then((response) => {
      setLoading(false);

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        loggedIn(true);
      }
    })
    .catch((error: AxiosError) => {
      setLoading(false);
      setError(error.response ? error.response.data.detail : 'Server seems to be offline');
    });
};

export { makeLoginRequest };
