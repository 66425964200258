import axios from 'axios';

import { getReactAppAPIURL } from '../utils/getWindowConfigOption';

export const checkToken = () => {
  // eslint-disable-next-line
  const url = `${getReactAppAPIURL()}/protected`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return (
    axios
      .get(url, config)
      .then(() => true)
      // eslint-disable-next-line no-console
      .catch((err) => {
        console.warn('err', err.message);
        return false;
      })
  );
};
