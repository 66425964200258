import * as React from 'react';

import {
  Action,
  ActionTypes,
  ApplicationProviderProps,
  ApplicationState,
  ContextValue,
} from './ApplicationContextTypes';
import { initialState } from '../state/InitialState';
import { stateReducer } from '../state/Reducers';

const ApplicationContext = React.createContext<ContextValue | null>(null);

export const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const [state, dispatch] = React.useReducer<React.Reducer<ApplicationState, Action>>(stateReducer, initialState);

  return (
    <ApplicationContext.Provider
      value={{
        state: {
          ...state,
        },
        dispatch,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = (): ContextValue => {
  const context = React.useContext(ApplicationContext);

  if (!context) {
    throw new Error('useApplicationContext must be used within an ApplicationProvider');
  }

  return context;
};
