import * as React from 'react';

import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';
import { PageContent } from '../../components/molecules/PageContent';
import { ConceptSettings } from '../../components/organisms/ConceptSettings';
import { StrategyChoices } from '../../components/organisms/StrategyChoices';
import { StrategyCharts } from '../../components/templates/StrategyCharts/StrategyCharts';
import { useTheme } from '../../context/ThemeContext';

import * as css from './AnalyseConceptsStyles';

const AnalyseConcepts = React.memo(() => {
  const theme = useTheme();

  return (
    <div>
      <AppHeader />
      <FlowNavigation />
      <PageContent className={css.columns(theme)}>
       <ConceptSettings />
        <div className={css.rows(theme)}>
          <StrategyCharts />
          <StrategyChoices />
        </div>
      </PageContent>
    </div>
  );
});

AnalyseConcepts.displayName = 'AnalyseConcepts';

export { AnalyseConcepts };
