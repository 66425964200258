import * as React from 'react';

import { useElementDimensions } from '../../../../../hooks/Dimensions';
import { useImageMap } from '../../../../../hooks/useImageMap';

import {
  AssetMapRoot,
  OverlayContainer,
  Locator,
  Popup,
  PopupTitle,
  PopupItems,
  Locators,
  LocatorSquare,
} from './AssetMapStyles';
import 'ol/ol.css';

const AssetMap = React.memo(() => {
  const ref = React.useRef<HTMLDivElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const dimensions = useElementDimensions(ref);
  const { locatorStyle, location: nearestLocation, locations, mapDimension } = useImageMap(imageRef, dimensions);

  const getImageStyle = () => {
    return {
      height: dimensions.width > dimensions.height ? '100%' : 'auto',
      width: dimensions.height > dimensions.width ? '100%' : 'auto',
    };
  };

  return (
    <div>
      <AssetMapRoot ref={ref} id="asset-map">
        <img ref={imageRef} style={getImageStyle()} src="images/olie_en_gas_poster_met_infra_02_04.jpg" />
        <Locators>
          {locations.map((location, index) => {
            const offsetY = location.y <= 0.72 ? 0.05 : -0.15;
            const offsetX = location.y <= 0.72 ? 0.05 : 0.10;
            return (
              <Locator
                visible={!!(nearestLocation && location.id === nearestLocation.id)}
              >
                {location.squares.map((square) => {
                  return (
                    <LocatorSquare
                      width={mapDimension.width * square.width}
                      height={mapDimension.height * square.height}
                      left={mapDimension.width * square.x}
                      top={mapDimension.height * square.y}
                    />
                  );
                })}
                <Popup
                  left={mapDimension.width * (location.x + offsetX)}
                  top={mapDimension.height * (location.y + offsetY)}
                >
                  <PopupTitle>{location.name}</PopupTitle>
                  <PopupItems>
                    {location.items.map((item, index) => {
                      return (
                        <div key={index}>
                          <div>{item.name}</div>
                          <div>{item.value}</div>
                        </div>
                      );
                    })}
                  </PopupItems>
                </Popup>
              </Locator>
            );
          })}
        </Locators>
      </AssetMapRoot>
    </div>
  );
});

AssetMap.displayName = 'AssetMap';

export { AssetMap };
