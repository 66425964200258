import styled from '@emotion/styled/macro';
import { css } from '@emotion/css';

export const SLIDER_WIDTH = 128;
export const THUMB_WIDTH = 15;

export const RangeSliderRoot = styled.div`
  display: grid;
  grid-template-rows: 24px 14px;
  margin-bottom: ${(props) => props.theme.spacing07};

  &:first-of-type {
    margin-top: ${(props) => `${props.theme.spacing05}`};
  }

  position: relative;
`;

export const RangeSliderTitle = styled.div`
  font-size: 12px;
  letter-spacing: 0.32px;
  color: ${(props) => props.theme.text01};
  font-weight: 400;
  display: block;
  vertical-align: baseline;
`;

interface RangeSliderValueProps {
  right?: boolean;
}

export const RangeSliderValue = styled.span<RangeSliderValueProps>`
  font-size: 12px;
  margin-top: 6px;
  text-align: ${(props) => (props.right ? 'right' : 'left')};
  color: ${(props) => props.theme.text02};
`;

interface RangeSliderValuesProps {
  disabled?: boolean;
}

export const RangeSliderValues = styled.div<RangeSliderValuesProps>`
  position: relative;
  padding-left: 0;
  display: grid;
  grid-row: 2;
  grid-template-columns: 32px ${SLIDER_WIDTH}px 32px;
  align-items: center;

  input[type='range'] {
    -webkit-appearance: none;
    background-color: ${(props) => props.theme!.activeSecondary};
    height: 1px;
    margin-top: 2px;
    pointer-events: none;

    &::-webkit-slider-runnable-track {
      height: 1px;
      margin: 0;
      width: 100%;
      cursor: pointer;
      background: #979797;
      pointer-events: none;
    }

    &::-webkit-slider-thumb {
      pointer-events: auto;
      width: ${THUMB_WIDTH}px;
      -webkit-appearance: none;
      height: 15px;
      border-radius: 50%;
      cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
      background: ${(props) => (props.disabled ? '#AAA' : 'white')};
      z-index: 999;
      position: relative;
      margin-top: -7px;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: -4px;
      left: 0;
      border-radius: 50%;
      border: 1px solid #979797;
      background-color: black;
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: -4px;
      right: 0;
      border-radius: 50%;
      border: 1px solid #979797;
      background-color: black;
    }

    &:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }
  }
`;

export const RangeSliderBar = styled.span`
  grid-column: 2;
  display: grid;
  place-items: center;
  position: relative;
  max-width: 220px;
`;

export const range = css`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 3px;
  outline: none;
  width: ${SLIDER_WIDTH}px;
`;

export const rangeInverted = css`
  direction: rtl;
`;
