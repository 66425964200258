import * as React from 'react';
import classNames from 'classnames';

import {
  RangeDualSliderRoot,
  RangeDualSliderTitle,
  RangeDualSliderValues,
  RangeDualSliderBar,
  RangeDualSliderBox,
  RangeDualSliderFillTracks,
  RangeSliderValue,
  rangeInverted,
  range,
  SLIDER_WIDTH,
  THUMB_WIDTH,
} from './RangeDualSliderStyles';

interface RangeSliderProps {
  title: string;
  name: string;
  id: string | number;
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  step?: number;
  setMinValue?: (value: number, name: string) => void;
  setMaxValue?: (value: number, name: string) => void;
  inverted?: boolean;
  percentage?: boolean;
}

const RangeDualSlider = React.memo<RangeSliderProps>(
  ({
    title,
    id,
    min,
    name,
    max,
    maxValue,
    minValue,
    step = 0.1,
    setMaxValue,
    setMinValue,
    inverted = false,
    percentage = false,
  }) => {
    const handleOnChangeMin = (event: React.ChangeEvent<HTMLInputElement>) => {
      const parsedFloat = parseFloat(event.target.value);

      if (setMinValue) {
        if (maxValue === minValue && parsedFloat > maxValue) {
          if (setMaxValue) {
            setMaxValue(parsedFloat, name);
          }
        } else {
          setMinValue(Math.min(parsedFloat, maxValue), name);
        }
      }
    };

    const handleOnChangeMax = (event: React.ChangeEvent<HTMLInputElement>) => {
      const parsedFloat = parseFloat(event.target.value);

      if (setMaxValue) {
        if (maxValue === minValue && parsedFloat < minValue) {
          if (setMinValue) {
            setMinValue(parsedFloat, name);
          }
        } else {
          setMaxValue(Math.max(minValue, parsedFloat), name);
        }
      }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const ratio = (SLIDER_WIDTH - THUMB_WIDTH) / (max - min);

    return (
      <RangeDualSliderRoot>
        <RangeDualSliderTitle>{title}</RangeDualSliderTitle>
        <RangeDualSliderValues>
          <RangeSliderValue>
            {percentage && 'P'}
            {(inverted ? maxValue : minValue).toFixed(0)}
          </RangeSliderValue>
          {/*<RangeDualSliderBox>*/}
          {/*  <input*/}
          {/*    name={name}*/}
          {/*    id={id + '_box'}*/}
          {/*    type="number"*/}
          {/*    value={(inverted ? maxValue : minValue).toFixed(1)}*/}
          {/*    onChange={handleOnChangeMin}*/}
          {/*  />*/}
          {/*</RangeDualSliderBox>*/}
          <RangeDualSliderBar>
            <input
              id={id + '_slider'}
              name={name}
              type="range"
              min={min}
              max={max}
              step={step}
              value={minValue.toFixed(1)}
              className={classNames(range, { [rangeInverted]: inverted })}
              onChange={handleOnChangeMin}
              onClick={handleClick}
            />
            <input
              id={id + '_slider_max'}
              name={name}
              type="range"
              value={maxValue.toFixed(1)}
              min={min}
              max={max}
              step={step}
              className={classNames(range, { [rangeInverted]: inverted })}
              onChange={handleOnChangeMax}
              onClick={handleClick}
            />
            <RangeDualSliderFillTracks
              style={{
                left: inverted
                  ? `${(7 + ratio * (max - maxValue)).toFixed(0)}px`
                  : `${(7 + ratio * (minValue - min)).toFixed(0)}px`,
                width: inverted
                  ? `${(ratio * (maxValue - minValue)).toFixed(0)}px`
                  : `${(ratio * (maxValue - minValue)).toFixed(0)}px`,
              }}
            />
          </RangeDualSliderBar>
          <RangeSliderValue right>
            {percentage && 'P'}
            {(inverted ? minValue : maxValue).toFixed(0)}
          </RangeSliderValue>
          {/*<RangeDualSliderBox>*/}
          {/*  <input*/}
          {/*    name={name}*/}
          {/*    id={id + '_box'}*/}
          {/*    type="number"*/}
          {/*    value={(inverted ? minValue : maxValue).toFixed(1)}*/}
          {/*    onChange={handleOnChangeMax}*/}
          {/*  />*/}
          {/*</RangeDualSliderBox>*/}
        </RangeDualSliderValues>
      </RangeDualSliderRoot>
    );
  },
);

export { RangeDualSlider };
