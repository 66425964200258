import { Action, ApplicationState } from '../context/ApplicationContextTypes';
import { sessionReducer } from './SessionReducer';
import { notificationReducer } from './NotificationReducer';
import { strategyReducer } from './StrategyReducer';
import { reviewReducer } from './ReviewStateReducers';

export const stateReducer = (previouseState: ApplicationState, action: Action): ApplicationState => {
  console.log(action.type);

  return {
    ...previouseState,
    review: reviewReducer(previouseState.review, action),
    session: sessionReducer(previouseState.session, action),
    strategy: strategyReducer(previouseState.strategy, action),
    notifications: notificationReducer(previouseState.notifications, action),
  };
};
