import styled from '@emotion/styled';
import { css } from '@emotion/css';

export const EconomicTradeOffsChartContainerRoot = styled.div`
  position: relative;
`;

export const Subtitle = styled.sub`
  font-size: 12px;
  margin-left: ${(props) => props.theme.spacing03};

  vertical-align: baseline;
  position: relative;
  top: 0em;
`;

export const icon = css`
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 20px;
  fill: white;
  background-color: black;
  padding: 4px;
  width: 24px;
  height: 24px;
`;
