import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { checkToken } from '../../api/checkToken';
import { Login } from './Login';
import { useSessionState } from '../../state/SessionState';
import { LoginStatus } from '../../state/SessionStateTypes';
import { App } from '../../components/layout/App';

const LoginContainer: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { session, setLoginStatus } = useSessionState();

  useEffect(() => {
    async function persistentLogin() {
      const isTokenValid = await checkToken();

      if (isTokenValid) {
        setLoginStatus(LoginStatus.LoggedOn);
      } else {
        setLoginStatus(LoginStatus.LoggedOff);
      }
    }

    persistentLogin().catch((): void => {
      setLoginStatus(LoginStatus.LoggedOff);
    });
  }, [history, session.loginStatus]);

  const handleLogin = (state: boolean) => {
    if (state) {
      setLoginStatus(LoginStatus.LoggedOn);
    } else {
      setLoginStatus(LoginStatus.LoggedOff);
    }
  };

  return session.loginStatus === LoginStatus.LoggedOn ? (
    <App />
  ) : (
    <Login validating={session.loginStatus === LoginStatus.Validating} handleLogin={handleLogin} />
  );
};

export { LoginContainer };
