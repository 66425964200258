import { useApplicationContext } from '../context/ApplicationContext';
import { ActionTypes } from '../context/ApplicationContextTypes';
import { NotificationType } from './NotificationStateTypes';

export interface NotificationCreated {
  type: ActionTypes.NotificationMessageCreated;
  data: {
    title?: string;
    caption: string;
    notificationType: NotificationType;
    notificationIndex: number;
  };
}

export interface NotificationTimeout {
  type: ActionTypes.NotificationMessageTimeout;
}

export const useNotificationState = () => {
  const { dispatch, state } = useApplicationContext();

  const notificationTimeout = () => {
    dispatch({
      type: ActionTypes.NotificationMessageTimeout,
    });
  };

  const notificationCreated = (caption: string, notificationType = NotificationType.success, title?: string) => {
    const newIndex = state!.notifications.notificationIndex + 1;

    dispatch({
      type: ActionTypes.NotificationMessageCreated,
      data: {
        title,
        caption,
        notificationType,
        notificationIndex: newIndex,
      },
    });
  };

  return {
    notifications: state!.notifications,
    notificationCreated,
    notificationTimeout,
  };
};
