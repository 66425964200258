import * as React from 'react';

import { TimeSeriesChart } from './molecules';
import { ChartTitle } from '../../../atoms/CharTitle';
import { Dropdown } from 'carbon-components-react';
import {TimeSeriesAxisTypes, TimeSeriesLabels} from '../../../../state/StrategyStateConstants';
import { useStrategyState } from '../../../../state/StrategyState';
import { OnChangeData } from 'carbon-components-react/lib/components/Dropdown/Dropdown';

import { TimeSeriesChartContainerRoot, DropDownContainer } from './TimeSetiesContainerChartContainerStyles';
import {Subtitle} from "./EconomicTradeOffsChartContainerStyles";

const TimeSeriesChartContainer = React.memo(() => {
  const { strategy, timeSeriesAxisChanged } = useStrategyState();

  const handleTimeSeriesAxisChange = (data: OnChangeData<TimeSeriesAxisTypes>) => {
    timeSeriesAxisChanged(data.selectedItem!);
  };

  const title = <span>Time series<Subtitle>Neptune share</Subtitle></span>

  return (
    <TimeSeriesChartContainerRoot>
      <ChartTitle title={title} marginLeft="42px" marginBottom="26px" />

      <DropDownContainer>
        <Dropdown<TimeSeriesAxisTypes>
          id="time-series-axis"
          items={Object.values(TimeSeriesAxisTypes)}
          label=""
          titleText=""
          itemToString={(item) => TimeSeriesLabels[item]}
          selectedItem={strategy.timeSeriesAxis}
          onChange={handleTimeSeriesAxisChange}
        />
      </DropDownContainer>

      <TimeSeriesChart />
    </TimeSeriesChartContainerRoot>
  );
});

TimeSeriesChartContainer.displayName = 'TimeSeriesChartContainer';

export { TimeSeriesChartContainer };
