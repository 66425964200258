import * as React from 'react';

import {
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeriesCanvas,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';

import { legend, ProductionTimeSeriesRoot } from './ProductionTimeSeriesStyles';
import { useReviewState } from '../../../../../state/ReviewState';
import { ProductionCategoryLabels, TimingTypes, TimingValues } from '../../../../../state/ReviewStateTypes';
import { MAX_YEARS } from './CostTimeSeries';

export const legendItems = [
  { title: 'low', color: '#FF9833' },
  { title: 'medium', color: '#283473' },
  { title: 'high', color: '#8CBEC5' },
];

export const getColor = (point: { x: number; y: number; setting: string }) => {
  if (point.setting === 'L') {
    return legendItems[0].color;
  }

  if (point.setting === 'M') {
    return legendItems[1].color;
  }

  return legendItems[2].color;
};

const ProductionTimeSeries = React.memo(() => {
  const { review } = useReviewState();

  const getData = () => {
    if (!review.asset || !review.project) {
      return [];
    }

    const series = review.assetProjectTimeSeries.filter((serie) => {
      return (
        (!review.asset || serie.asset === review.asset) &&
        (!review.project || serie.projectName === review.project) &&
        serie.category === review.productionCategory &&
        (review.timing === TimingTypes.none || TimingValues[review.timing] === serie.setting)
      );
    });

    if (series.length === 0) {
      return [];
    }

    const years = [...Array(59)].map((item, index) => index + 2022);

    return series.map((serie) => {
      return years.slice(0, MAX_YEARS).map((year) => {
        return {
          x: year,
          y: serie.timeseries[year],
          setting: serie.setting,
        };
      });
    });
  };

  const data = getData();

  const handleXTickFormat = (value: string) => value;
  const handleYTickFormat = (value: string) => value;

  return (
    <ProductionTimeSeriesRoot>
      <XYPlot width={866} height={350}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <DiscreteColorLegend items={legendItems} className={legend} />
        <XAxis
          tickFormat={handleXTickFormat}
          title="Year"
          tickLabelAngle={-45}
          style={{ title: { fontSize: '12px' } }}
        />
        <YAxis
          tickFormat={handleYTickFormat}
          title={`${ProductionCategoryLabels[review.productionCategory!]} (${
            review.categoryLabelUnitMapping[review.productionCategory!]
          })`}
          style={{
            title: {
              fontSize: '12px',
              textAnchor: 'start',
              transform: 'translate(10px, 6px) rotate(0deg)',
            },
          }}
        />
        {data.map((dataItem, index) => {
          return <LineSeriesCanvas colorType="literal" stroke={getColor(dataItem[0])} data={dataItem} key={index} />;
        })}
        {/*<AreaSeries className="area-elevated-series-1" color="#7e7e7e" opacity={0.5} data={data} />*/}
      </XYPlot>
    </ProductionTimeSeriesRoot>
  );
});

ProductionTimeSeries.displayName = 'ProductionTimeSeries';

export { ProductionTimeSeries };
