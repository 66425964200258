import * as React from 'react';

import { ChartTitle } from '../../../atoms/CharTitle';
import { ProductionTimeSeries } from './molecules';

import { ProductionTimeSeriesContainerRoot } from './ProductionTimeSeriesContainerStyles';

const ProductionTimeSeriesContainer = React.memo(() => {
  return (
    <ProductionTimeSeriesContainerRoot>
      <ChartTitle title="Production time series" />
      <ProductionTimeSeries />
    </ProductionTimeSeriesContainerRoot>
  );
});

ProductionTimeSeriesContainer.displayName = 'ProductionTimeSeriesContainer';

export { ProductionTimeSeriesContainer };
