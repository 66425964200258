/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import { SettingsPaneRoot } from './SettingsPaneStyles';

export interface SettingsPaneProps {
  css?: SerializedStyles;
  children: React.ReactNode;
}

const SettingsPane: React.FC<SettingsPaneProps> = (props) => {
  const { css, children } = props;

  return <SettingsPaneRoot css={css}>{children}</SettingsPaneRoot>;
};

export { SettingsPane };
