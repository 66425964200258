import * as React from 'react';

import { useStrategyState } from '../state/StrategyState';
import { fetchStrategies } from '../api/data-fetch';
import { StrategyMappings } from '../state/StrategyStateTypes';

export interface UseFetchStrategyMapping {
  isFetching: boolean;
  fetchData: () => void;
  isError: boolean;
}

export const useFetchStrategyMapping = (): UseFetchStrategyMapping => {
  const { strategyMappingFetched } = useStrategyState();
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [isError, setError] = React.useState<boolean>(false);

  const fetchData = async () => {
    try {
      setFetching(true);

      const response = await fetchStrategies();

      const sanitizeResponseData = (data: StrategyMappings) => {
        const result = {};
        Object.keys(data).forEach((key) => {
          const strategy = data[key];
          const sanitizedStrategy = {};

          Object.keys(strategy).forEach((subKey) => {
            sanitizedStrategy[subKey] = `${strategy[subKey]}`;
          });

          result[key] = sanitizedStrategy;
        });

        return result;
      };

      const sanitizedData = sanitizeResponseData(response.data);
      console.log('Fetched stategy data', sanitizedData);

      strategyMappingFetched(sanitizedData);
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    isFetching,
    fetchData,
    isError,
  };
};
