import { css } from '@emotion/css';

import { CarbonTheme } from '../../styles/theme';

export const columns = (theme: CarbonTheme) => css`
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  display: grid;
  grid-template-columns: 224px auto;
  grid-gap: 1rem;

  background-color: ${theme.ui05};
`;
