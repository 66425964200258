import * as React from 'react';
import { XAxis, XYPlot, YAxis, LineSeries, MarkSeriesPoint, MarkSeries, ChartLabel } from 'react-vis';

import { useStrategyState } from '../../../../../state/StrategyState';
import { useTradeOffRange } from '../../../../../hooks/useTradeOffRange';

import { ComplexityChartRoot } from './ComplexityChartStyles';

const GRID_LINE_COLOR = '#E6E6E9';

const ComplexityChart = React.memo((props) => {
  const nearestXYRef = React.useRef<MarkSeriesPoint | null>(null);

  const { isInRange } = useTradeOffRange();
  const { strategy, strategySelectedChanged } = useStrategyState();
  const {
    highlightedStrategyChoice,
    complexities,
    selectedStrategyId,
    strategies,
    filteredResultMetrics,
    filteredResultTrigger,
  } = strategy;

  const complexityKeys = Object.keys(complexities);

  const data = React.useMemo(() => {
    const sizes = filteredResultMetrics.reduce<{ min: number; max: number }>(
      (accumulator, nextMetric) => {
        return {
          min: Math.min(accumulator.min, nextMetric.profitability_index),
          max: Math.min(1.5, Math.max(accumulator.max, nextMetric.profitability_index)),
        };
      },
      { min: Infinity, max: 0 },
    );

    const delta = sizes.max - sizes.min;
    const sizeMultiplier = 15 / delta;

    const result = complexityKeys
      .filter((key) => {
        return filteredResultMetrics.find((metric) => metric.strategyId === key);
      })
      .map((key) => {
        const metric = filteredResultMetrics.find((metric) => metric.strategyId === key);

        //console.log(sizeMultiplier * Math.min(1.5, metric!.profitability_index));

        return {
          x: complexities[key].commercial_complexity,
          y: complexities[key].technical_complexity,
          size: 5 + sizeMultiplier * Math.min(1.5, metric!.profitability_index),
          profitabilityIndex: metric!.profitability_index,
          strategyId: key,
          strategy: strategies[key],
          inRange: metric ? isInRange(metric!) : true,
        };
      })
      .filter((complexity) => {
        return complexity.strategy['Riser tower - when to take FID'] !== '2023';
      });

    if (!selectedStrategyId) {
      return result;
    }

    const item = result.find((findItem) => findItem.strategyId === selectedStrategyId);

    if (!item) {
      return result;
    }

    return [...result.filter((filterItem) => filterItem.strategyId !== selectedStrategyId), item!];
  }, [filteredResultTrigger, complexityKeys.length, highlightedStrategyChoice, selectedStrategyId]);

  const handleGetColor = (point: MarkSeriesPoint) => {
    if (
      highlightedStrategyChoice &&
      `${point.strategy[highlightedStrategyChoice.choiceKey]}` === `${highlightedStrategyChoice.value}`
    ) {
      return '#097b90';
    }

    if (selectedStrategyId && selectedStrategyId === point.strategyId) {
      return '#B3DEE0';
    }

    if (!point.inRange) {
      return '#DADADA';
    }

    return '#7E7E7E';
  };

  const handleGetOpacity = (point: MarkSeriesPoint) => {
    if (
      highlightedStrategyChoice &&
      point.strategy[highlightedStrategyChoice.choiceKey] === highlightedStrategyChoice.value
    ) {
      return 0.8;
    }

    if (!point.inRange || (selectedStrategyId && selectedStrategyId === point.strategyId)) {
      return 1;
    }

    return 0.4;
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!nearestXYRef.current) {
      strategySelectedChanged('');

      return;
    }

    console.log(nearestXYRef.current);

    strategySelectedChanged(nearestXYRef.current.strategyId);
  };

  const handleNearestXY = (point: MarkSeriesPoint) => {
    nearestXYRef.current = point;
  };

  return (
    <ComplexityChartRoot onClick={handleClick}>
      <XYPlot width={480} height={416} margin={{ left: 55 }}>
        <ChartLabel text="Low" includeMargin={true} xPercent={0.265} yPercent={0.82} />
        <ChartLabel text="Medium" includeMargin={true} xPercent={0.5} yPercent={0.82} />
        <ChartLabel text="High" includeMargin={true} xPercent={0.83} yPercent={0.82} />

        <ChartLabel text="Low" includeMargin={true} xPercent={0.04} yPercent={0.65} />
        <ChartLabel text="Medium" includeMargin={true} xPercent={0} yPercent={0.34} />
        <ChartLabel text="High" includeMargin={true} xPercent={0.035} yPercent={0.035} />

        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 0.3333, y: 0 },
            { x: 0.3333, y: 1 },
          ]}
        />
        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 0.6666, y: 0 },
            { x: 0.6666, y: 1 },
          ]}
        />
        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 1, y: 0 },
            { x: 1, y: 1 },
          ]}
        />
        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 0, y: 0.3333 },
            { x: 1, y: 0.3333 },
          ]}
        />
        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 0, y: 0.6666 },
            { x: 1, y: 0.6666 },
          ]}
        />
        <LineSeries
          color={GRID_LINE_COLOR}
          colorType="literal"
          data={[
            { x: 0, y: 1 },
            { x: 1, y: 1 },
          ]}
        />

        <ChartLabel text="size = Profitability index" xPercent={0.57} yPercent={-0.08} />

        <XAxis title="Commercial complexity" tickFormat={() => ''} />
        <YAxis
          title="Technical complexity"
          style={{ title: { textAnchor: 'start', transform: 'translate(6px,6px) rotate(0deg)' } }}
          tickFormat={() => ''}
        />
        <MarkSeries
          sizeType="literal"
          colorType="literal"
          opacityType="literal"
          data={data}
          getColor={handleGetColor}
          getOpacity={handleGetOpacity}
          onNearestXY={handleNearestXY}
        />
      </XYPlot>
    </ComplexityChartRoot>
  );
});

ComplexityChart.displayName = 'ComplexityChart';

export { ComplexityChart };
