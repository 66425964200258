import { ApplicationState } from '../context/ApplicationContextTypes';
import { LoginStatus } from './SessionStateTypes';
import { NotificationType } from './NotificationStateTypes';
import { NeptuneHighLevels, StrategyLevels, StrategySliderTypes, TimeSeriesAxisTypes } from './StrategyStateConstants';
import { TimingTypes } from './ReviewStateTypes';

export const initialState: ApplicationState = {
  session: {
    loginStatus: LoginStatus.Validating,
  },
  review: {
    asset: null,
    assets: [],
    project: null,
    projects: [],
    productionCategory: 'gas',
    productionCategories: ['gas', 'condensate', 'oil', 'hydrogen', 'carbon intensity'],
    costCategory: 'facility capex',
    costCategories: [
      'facility capex',
      'processing tariff',
      'transport TPC',
      'opex',
      'drilling capex',
      'abex',
      'oil tariff',
      'maintenance capex',
      'tariff income from 3rd party',
    ],
    timing: TimingTypes.none,
    assetProjectTimeSeries: [],
    categoryLabelUnitMapping: {},
  },
  strategy: {
    selectedScenarioId: '0',
    strategies: {},
    scenarios: {},
    complexities: {},
    filteredResultMetrics: [],
    filteredResultTradeOff: [],
    filteredResultTrigger: false,
    parameterTrigger: false,
    selectedStrategyId: '',
    economicResults: {},
    highlightedStrategyChoice: null,
    generalSettingsToggles: {
      neptuneShare: {
        name: 'neptuneShare',
        title: 'Neptune share',
        status: false,
      },
      abexDiscounting: {
        name: 'abexDiscounting',
        title: 'ABEX discounting',
        status: false,
      },
    },
    scenarioSliders: {
      reservoirOutcome: {
        name: 'reservoirOutcome',
        title: 'Reservoir outcome',
        range: {
          min: 1,
          max: 3,
        },
        percentage: false,
        step: 1,
        value: 2,
        inverted: false,
        disabled: false,
        sliderType: StrategySliderTypes.single,
      },
      operatingCost: {
        name: 'operatingCost',
        title: 'Operating cost',
        range: {
          min: 1,
          max: 3,
        },
        percentage: false,
        step: 1,
        value: 2,
        inverted: false,
        disabled: false,
        sliderType: StrategySliderTypes.single,
      },
      capitalExpenditure: {
        name: 'capitalExpenditure',
        title: 'Capital expenditure',
        range: {
          min: 1,
          max: 3,
        },
        percentage: false,
        step: 1,
        value: 2,
        inverted: false,
        disabled: false,
        sliderType: StrategySliderTypes.single,
      },
      f17FIDTiming: {
        name: 'f17FIDTiming',
        title: 'F17 FID timing',
        range: {
          min: 1,
          max: 3,
        },
        percentage: false,
        step: 1,
        value: 2,
        inverted: false,
        disabled: false,
        sliderType: StrategySliderTypes.single,
      },
      riseTowerIncrementalProcessingTariff: {
        name: 'riseTowerIncrementalProcessingTariff',
        title: 'Incremental processing tariff',
        range: {
          min: 1,
          max: 3,
        },
        percentage: false,
        step: 1,
        value: 2,
        inverted: false,
        disabled: false,
        sliderType: StrategySliderTypes.single,
      },
    },
    f3SettingsToggles: {
      f5ABOverF3B: {
        name: 'f5ABOverF3B',
        title: 'F5-A and B over F3-B',
        status: true,
      },
      f17OverF3B: {
        name: 'f17OverF3B',
        title: 'F17 over F3-B',
        status: true,
      },
      f6IJsselOverF3B: {
        name: 'F6IJsselOverF3B',
        title: 'F6-IJssel over F3-B',
        status: true,
      },
      polluxCastorOverF3B: {
        name: 'polluxCastorOverF3B',
        title: 'Pollux&Castor over F3-B',
        status: true,
      },
      f4CShallowOverF3B: {
        name: 'f4CShallowOverF3-B',
        title: 'F4-C Shallow over F3-B',
        status: true,
      },
      f4CDeepOverF3B: {
        name: 'f4CDeepOverF3B',
        title: 'F4-C Deep over F3-B',
        status: true,
      },
    },
    economicSettings: {
      assetProjectInfoSetting: {
        name: 'asset_project_info_setting',
        title: 'Asset project info',
        value: StrategyLevels.medium,
      },
      inflationAndDiscountSetting: {
        name: 'inflation_and_discount_setting',
        title: 'Inflation and discount',
        value: StrategyLevels.medium,
      },
      oilPriceDiscountSetting: {
        name: 'oil_price_discount_setting',
        title: 'Oil price discount',
        value: StrategyLevels.medium,
      },
      projectTimingsSetting: {
        name: 'project_timings_setting',
        title: 'Project timing',
        value: StrategyLevels.medium,
      },
      hydrocarbonPrice: {
        name: 'hydrocarbonPrice',
        title: 'Hydrocarbon price',
        value: StrategyLevels.medium,
      },
      co2Tax: {
        name: 'co2Tax',
        title: 'CO2 Tax',
        value: NeptuneHighLevels.neptune,
      },
      discountRate: {
        name: 'discountRate',
        title: 'Discount rate',
        value: 10,
      },
      tradeOffRange: {
        x: 100,
        y: 5,
        yLog: 10,
      },
    },
    timeSeriesAxis: TimeSeriesAxisTypes.area_cashflow,
  },
  notifications: {
    notificationType: NotificationType.disabled,
    title: '',
    caption: '',
    notificationIndex: 0,
  },
};
