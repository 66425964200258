import { css } from '@emotion/css';

import { CarbonTheme } from '../../styles/theme';

export const header = (theme: CarbonTheme) => css`
  .bx--header a.bx--header__name {
    padding-left: ${theme.spacing05};
  }
`;

export const image = css`
  margin-left: 1rem;
  height: 36px;
`;

export const headerNameTitle = css`
  font-weight: normal;
`;

export const headerGlobalBar = (theme: CarbonTheme) => css`
  .bx--header__action:last-child {
    margin-right: ${theme.spacing05};
  }
`;
