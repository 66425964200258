import * as React from 'react';

import { ProductionTimeSeriesContainer, AssetMapContainer, CostTimeSeriesContainer } from './organisms';

import { ReviewChartsRoot, ReviewChartsTimeSeries } from './ReviewChartsStyles';

const ReviewCharts = React.memo(() => {
  return (
    <ReviewChartsRoot>
      <ReviewChartsTimeSeries>
        <ProductionTimeSeriesContainer />
        <CostTimeSeriesContainer />
      </ReviewChartsTimeSeries>
      <AssetMapContainer />
    </ReviewChartsRoot>
  );
});

ReviewCharts.displayName = 'StrategyCharts';

export { ReviewCharts };
