import styled from '@emotion/styled/macro'

export const FlowNavigationRoot = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  height: 3rem;

  span {
    font-size: 0.85rem;
    line-height: 1.5;
  }
`
