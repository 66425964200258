import { ActionTypes } from '../context/ApplicationContextTypes';
import { useApplicationContext } from '../context/ApplicationContext';
import {
  EconomicResultMetrics,
  ScenarioMappings,
  StrategyChoice,
  StrategyComplexities,
  StrategyMappings,
  StrategyScenarioEconomicResultMetrics,
} from './StrategyStateTypes';
import { NeptuneHighLevels, StrategyLevels, TimeSeriesAxisTypes } from './StrategyStateConstants';

export interface ConceptSliderChanged {
  type: ActionTypes.StrategyScenarioSliderChanged;
  data: {
    name: string;
    minValue: number;
    maxValue: number | null;
  };
}

export interface NeptuneLevelChanged {
  type: ActionTypes.StrategyNeptuneLevelChanged;
  data: {
    name: string;
    value: NeptuneHighLevels;
  };
}

export interface GeneralToggleChanged {
  type: ActionTypes.StrategyGeneralToggleChanged;
  data: {
    name: string;
    status: boolean;
  };
}

export interface TimeSeriesAxisChanged {
  type: ActionTypes.StrategyTimeSeriesAxisChanged;
  data: {
    timeSeriesAxis: TimeSeriesAxisTypes;
  };
}

export interface F3ToggleChanged {
  type: ActionTypes.StrategyF3ToggleChanged;
  data: {
    name: string;
    status: boolean;
  };
}

export interface StrategyMappingFetched {
  type: ActionTypes.StrategyMappingFetched;
  data: {
    strategies: StrategyMappings;
  };
}

export interface StrategyEconomicResultsFetched {
  type: ActionTypes.StrategyEconomicResultsFetched;
  data: {
    filteredResultMetrics: EconomicResultMetrics[];
    filteredResultTradeOff: EconomicResultMetrics[];
  };
}

export interface StrategyParameterSettingsChanged {
  type: ActionTypes.StrategyParameterSettingsChanged;
  data: {
    selectedScenarioId: string;
  };
}

export interface StrategyComplexitiesFetched {
  type: ActionTypes.StrategyComplexitiesFetched;
  data: {
    complexities: StrategyComplexities;
  };
}

export interface ScenarioMappingFetched {
  type: ActionTypes.StrategyScenarioMappingFetched;
  data: {
    scenarios: ScenarioMappings;
  };
}

export interface EconomicLevelChanged {
  type: ActionTypes.StrategyEconomicLevelChanged;
  data: {
    name: string;
    level: StrategyLevels;
  };
}

export interface DiscountRateChanged {
  type: ActionTypes.StrategyDiscountRateChanged;
  data: {
    rate: number;
  };
}

export interface TradeOffsRangeChanged {
  type: ActionTypes.StrategyEconomicTradeOffsRangeChanged;
  data: {
    range: { x: number; y: number; yLog: number };
  };
}

export interface StrategySelectedChanged {
  type: ActionTypes.StrategySelectedChanged;
  data: {
    strategyId: string;
  };
}

export interface StrategyHighlightedChoiceChanged {
  type: ActionTypes.StrategyHighlightedChoiceChanged;
  data: {
    choice: StrategyChoice | null;
  };
}

export const useStrategyState = () => {
  const { dispatch, state } = useApplicationContext();

  const conceptSliderChanged = (name: string, minValue: number, maxValue: number | null = null) => {
    dispatch({
      type: ActionTypes.StrategyScenarioSliderChanged,
      data: {
        name,
        maxValue,
        minValue,
      },
    });
  };

  const parameterSettingsChanged = (selectedScenarioId: string) => {
    dispatch({
      type: ActionTypes.StrategyParameterSettingsChanged,
      data: {
        selectedScenarioId,
      },
    });
  };

  const complexitiesFetched = (complexities: StrategyComplexities) => {
    dispatch({
      type: ActionTypes.StrategyComplexitiesFetched,
      data: {
        complexities,
      },
    });
  };

  const generalToggleChanged = (name: string, status: boolean) => {
    dispatch({
      type: ActionTypes.StrategyGeneralToggleChanged,
      data: {
        name,
        status,
      },
    });
  };

  const f3ToggleChanged = (name: string, status: boolean) => {
    dispatch({
      type: ActionTypes.StrategyF3ToggleChanged,
      data: {
        name,
        status,
      },
    });
  };

  const economicLevelChanged = (name: string, level: StrategyLevels) => {
    dispatch({
      type: ActionTypes.StrategyEconomicLevelChanged,
      data: {
        name,
        level,
      },
    });
  };

  const timeSeriesAxisChanged = (timeSeriesAxis: TimeSeriesAxisTypes) => {
    dispatch({
      type: ActionTypes.StrategyTimeSeriesAxisChanged,
      data: {
        timeSeriesAxis,
      },
    });
  };

  const neptuneLevelChanged = (name: string, value: NeptuneHighLevels) => {
    dispatch({
      type: ActionTypes.StrategyNeptuneLevelChanged,
      data: {
        name,
        value,
      },
    });
  };

  const discountRateChanged = (rate: number) => {
    dispatch({
      type: ActionTypes.StrategyDiscountRateChanged,
      data: {
        rate,
      },
    });
  };
  const tradeOffsRangeChanged = (range: { x: number; y: number; yLog: number }) => {
    dispatch({
      type: ActionTypes.StrategyEconomicTradeOffsRangeChanged,
      data: {
        range,
      },
    });
  };

  const strategySelectedChanged = (strategyId: string) => {
    dispatch({
      type: ActionTypes.StrategySelectedChanged,
      data: {
        strategyId,
      },
    });
  };

  const strategyHighlightedChoiceChanged = (choice: StrategyChoice | null) => {
    dispatch({
      type: ActionTypes.StrategyHighlightedChoiceChanged,
      data: {
        choice,
      },
    });
  };

  const strategyEconomicResultsFetched = (
    filteredResultMetrics: EconomicResultMetrics[],
    filteredResultTradeOff: EconomicResultMetrics[],
  ) => {
    return dispatch({
      type: ActionTypes.StrategyEconomicResultsFetched,
      data: {
        filteredResultMetrics,
        filteredResultTradeOff,
      },
    });
  };

  const strategyMappingFetched = (strategies: StrategyMappings) => {
    dispatch({
      type: ActionTypes.StrategyMappingFetched,
      data: {
        strategies,
      },
    });
  };

  const scenarioMappingFetched = (scenarios: ScenarioMappings) => {
    dispatch({
      type: ActionTypes.StrategyScenarioMappingFetched,
      data: {
        scenarios,
      },
    });
  };

  return {
    economicLevelChanged,
    strategy: state!.strategy,
    conceptSliderChanged,
    generalToggleChanged,
    complexitiesFetched,
    f3ToggleChanged,
    discountRateChanged,
    strategyEconomicResultsFetched,
    strategyMappingFetched,
    tradeOffsRangeChanged,
    scenarioMappingFetched,
    strategyHighlightedChoiceChanged,
    parameterSettingsChanged,
    neptuneLevelChanged,
    strategySelectedChanged,
    timeSeriesAxisChanged,
  };
};
