import * as React from 'react';
import classNames from 'classnames';

interface PageContentProps {
  className?: string | null;
  children: React.ReactNode;
}

const PageContent: React.FC<PageContentProps> = (props): React.ReactElement => {
  const { children, className } = props;

  return <div className={classNames('bx--content ', className)}>{children}</div>;
};

export { PageContent };
