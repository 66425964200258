import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  FormGroup,
  RadioButton,
  RadioButtonGroup,
  RadioButtonValue,
} from 'carbon-components-react';

import { useStrategyState } from '../../state/StrategyState';
import { RangeDualSlider } from '../molecules/RangeDualSlider';
import { ScenarioRangeSlider } from '../../state/StrategyStateTypes';
import { SettingsToggle } from '../molecules/SettingsToggle';
import { NeptuneHighLevels, StrategyLevels, StrategySliderTypes } from '../../state/StrategyStateConstants';
import { useTheme } from '../../context/ThemeContext';
import { SettingsPane } from '../molecules/SettingsPane';
import { StrategyLevelSelect } from '../molecules/StrategyLevelSelect';
import { RangeSlider } from '../molecules/RangeSlider';
import { NeptuneHighLevelSelect } from '../molecules/NeptuneHighSelect';

import { accordionItem, discountRateFormGroup, HR } from './ConceptSettingsStyles';

const ConceptSettings = React.memo(() => {
  const {
    strategy,
    conceptSliderChanged,
    generalToggleChanged,
    f3ToggleChanged,
    economicLevelChanged,
    neptuneLevelChanged,
    discountRateChanged,
  } = useStrategyState();
  const { scenarioSliders, generalSettingsToggles, f3SettingsToggles, economicSettings } = strategy;
  const scenarioSliderNames = Object.keys(scenarioSliders);
  //const generalSettingsToggleNames = Object.keys(generalSettingsToggles);
  const f3SettingsToggleNames = Object.keys(f3SettingsToggles);
  const theme = useTheme();

  const handleMinValue = (slider: ScenarioRangeSlider) => (minValue: number, name: string) => {
    conceptSliderChanged(name, minValue, slider.maxValue);
  };

  const handleMaxValue = (slider: ScenarioRangeSlider) => (maxValue: number, name: string) => {
    conceptSliderChanged(name, slider.minValue, maxValue);
  };

  const handleSetValue = (slider: ScenarioRangeSlider) => (value: number, name: string) => {
    conceptSliderChanged(name, value);
  };

  const handleGeneralToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    generalToggleChanged(event.target.name, event.target.checked);
  };

  const handleF3ToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    f3ToggleChanged(event.target.name, event.target.checked);
  };

  const handleDiscountRateChange = (newSelection: RadioButtonValue) => {
    discountRateChanged(newSelection as number);
  };

  const handleEconomicSettingChange = (settingName: string) => (newValue: RadioButtonValue) => {
    economicLevelChanged(settingName, newValue as StrategyLevels);
  };

  const handleNeptuneChange = (name: string, value: NeptuneHighLevels) => {
    neptuneLevelChanged(name, value);
  };

  return (
    <SettingsPane>
      <Accordion className="dark">
        <AccordionItem title="Scenario settings" className={accordionItem(theme)}>
          {scenarioSliderNames.map((sliderName) => {
            const slider = scenarioSliders[sliderName];

            return (
              <div key={sliderName}>
                {slider.sliderType === StrategySliderTypes.dual ? (
                  <RangeDualSlider
                    title={slider.title}
                    percentage={slider.percentage}
                    name={slider.name}
                    id={slider.name}
                    min={slider.range.min}
                    max={slider.range.max}
                    minValue={slider.minValue}
                    maxValue={slider.maxValue}
                    step={slider.step}
                    inverted={slider.inverted}
                    setMinValue={handleMinValue(slider)}
                    setMaxValue={handleMaxValue(slider)}
                  />
                ) : (
                  <RangeSlider
                    title={slider.title}
                    name={slider.name}
                    id={slider.name}
                    min={slider.range.min}
                    max={slider.range.max}
                    value={slider.value}
                    step={slider.step}
                    inverted={slider.inverted}
                    disabled={slider.disabled}
                    setValue={handleSetValue(slider)}
                  />
                )}
              </div>
            );
          })}

          <HR />

          {f3SettingsToggleNames.map((toggleName) => {
            const toggle = f3SettingsToggles[toggleName];

            return (
              <SettingsToggle
                id={`${toggleName}_toggle`}
                name={toggleName}
                key={toggleName}
                labelText={toggle.title}
                toggled={toggle.status}
                onChange={handleF3ToggleChange}
              />
            );
          })}
        </AccordionItem>

        <AccordionItem title="Economic settings" className={accordionItem(theme)}>
          <StrategyLevelSelect
            setting={economicSettings.hydrocarbonPrice}
            onValueChange={handleEconomicSettingChange('hydrocarbonPrice')}
          />

          <NeptuneHighLevelSelect setting={economicSettings.co2Tax} onValueChange={handleNeptuneChange} />

          <FormGroup legendText="Discount rate" className={discountRateFormGroup(theme)}>
            <RadioButtonGroup
              name="discount_rate"
              valueSelected={economicSettings.discountRate.value}
              onChange={handleDiscountRateChange}
            >
              <RadioButton id="rate_eight" labelText="8%" value={8} />
              <RadioButton id="rate_ten" labelText="10%" value={10} />
              <RadioButton id="rate_twelve" labelText="12%" value={12} />
            </RadioButtonGroup>
          </FormGroup>
        </AccordionItem>
      </Accordion>
    </SettingsPane>
  );
});

ConceptSettings.displayName = 'AnalyseSettingsConceptSettings';

export { ConceptSettings };
