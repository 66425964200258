import * as React from 'react';

import { ChartBubble20 } from '@carbon/icons-react';

import { EconomicTradeOffsChart } from './molecules';
import { ChartTitle } from '../../../atoms/CharTitle';

import { EconomicTradeOffsChartContainerRoot, Subtitle, icon } from './EconomicTradeOffsChartContainerStyles';

const EconomicTradeOffsChartContainer = React.memo(() => {
  const [isLog, setLog] = React.useState<boolean>(true);

  const handleToggleLog = () => {
    setLog(!isLog);
  };

  const title = (
    <span>
      Economic trade-offs<Subtitle>Pre-tax, Neptune share</Subtitle>
    </span>
  );

  return (
    <EconomicTradeOffsChartContainerRoot>
      <ChartTitle title={title} marginBottom="9px" />

      <ChartBubble20 className={icon} onClick={handleToggleLog} />

      <EconomicTradeOffsChart isLog={isLog} />
    </EconomicTradeOffsChartContainerRoot>
  );
});

EconomicTradeOffsChartContainer.displayName = 'EconomicTradeOffsChartContainer';

export { EconomicTradeOffsChartContainer };
