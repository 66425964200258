import styled from '@emotion/styled';

export const ESGChartContainerRoot = styled.div`
  position: relative;
  align-self: end;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  right: 36px;
  top: 20px;
  width: 150px;

  .bx--dropdown {
    height: ${(props) => props.theme.spacing07};

    .bx--list-box__label,
    .bx--list-box__menu-item__option {
      font-size: 12px;
    }
  }
`;
