import * as React from 'react';
import { ToastNotification } from 'carbon-components-react';

import { SmallWindowRoot } from './SmallWindowStyles';

interface SmallWindowProps {
  minimumWidth: number;
  currentWidth: number;
}

const SmallWindow = React.memo<SmallWindowProps>((props: SmallWindowProps) => {
  const { minimumWidth, currentWidth } = props;

  return (
    <SmallWindowRoot>
      <ToastNotification
        kind="warning"
        iconDescription="describes the close button"
        caption="Make the window larger"
        subtitle={
          <span>
            This app works best on screens over ${minimumWidth} pixels wide. Current width: ${currentWidth} pixels
          </span>
        }
        timeout={0}
        title="Browser window too small"
      />
    </SmallWindowRoot>
  );
});

SmallWindow.displayName = 'SmallWindow';

export { SmallWindow };
