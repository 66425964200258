import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import ProgressStep from '../molecules/ProgressStep';

import { FlowNavigationRoot } from './FlowNavigationStyles';

const flowSteps = [
  {
    path: 'review-input',
    label: 'Review input data',
  },
  {
    path: 'analyse-concepts',
    label: 'Analyse concepts',
  },
];

const FlowNavigation = React.memo(() => {
  const location = useLocation();

  const pathName = location.pathname === '/campaign-debug' ? '/campaign-view' : location.pathname;
  const flowStepsWithField = flowSteps.map((flow) => ({
    ...flow,
    disabled: false,
    path: `/${flow.path}`,
  }));

  const currentStep = flowStepsWithField.findIndex((step) => pathName.indexOf(step.path) > -1);

  return (
    <FlowNavigationRoot className={classNames('flow-nav')}>
      <ul className="bx--progress">
        {flowStepsWithField?.map((step, index) => (
          <ProgressStep
            href={step.path}
            label={step.label}
            key={step.label}
            disabled={step.disabled}
            current={index === currentStep}
            complete={index < currentStep}
          />
        ))}
      </ul>
    </FlowNavigationRoot>
  );
});

export { FlowNavigation };
