import { css } from '@emotion/css';
import {CarbonTheme} from '../../styles/theme';

export const headerRoot = (theme: CarbonTheme) => css`
  .bx--header__name {
    padding-left: ${theme.spacing05};
    font-size: 16px;
  }

  img {
    margin-left: ${theme.spacing05};
  }
`;
