import * as React from 'react';
import classNames from 'classnames';

import {
  RangeSliderRoot,
  RangeSliderTitle,
  RangeSliderValues,
  RangeSliderBar,
  RangeSliderValue,
  rangeInverted,
  range,
} from './RangeSliderStyles';

export interface RangeSliderProps {
  title: string;
  name: string;
  id: string | number;
  min: number;
  max: number;
  value: number;
  step?: number;
  setValue?: (value: number, name: string) => void;
  inverted?: boolean;
  percentage?: boolean;
  disabled?: boolean;
}

const RangeSlider = React.memo<RangeSliderProps>(
  ({
    title,
    id,
    min,
    name,
    max,
    value,
    step = 0.1,
    setValue,
    inverted = false,
    percentage = false,
    disabled = false,
  }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const parsedFloat = parseFloat(event.target.value);

      if (setValue) {
        setValue(parsedFloat, name);
      }
    };

    return (
      <RangeSliderRoot>
        <RangeSliderTitle>{title}</RangeSliderTitle>
        <RangeSliderValues disabled={disabled}>
          <RangeSliderValue>L</RangeSliderValue>
          <RangeSliderBar>
            <input
              disabled={disabled}
              id={id + '_slider'}
              name={name}
              type="range"
              min={min}
              max={max}
              step={step}
              value={value.toFixed(1)}
              className={classNames(range, { [rangeInverted]: inverted })}
              onChange={handleOnChange}
            />
          </RangeSliderBar>
          <RangeSliderValue right>H</RangeSliderValue>
        </RangeSliderValues>
      </RangeSliderRoot>
    );
  },
);

export { RangeSlider };
