import * as React from 'react';

import { HorizontalGridLines, VerticalBarSeriesCanvas, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';

import { useReviewState } from '../../../../../state/ReviewState';
import { CostCategoryLabels, TimingTypes, TimingValues } from '../../../../../state/ReviewStateTypes';
import { getColor } from './ProductionTimeSeries';

import { CostTimeSeriesRoot } from './CostTimeSeriesStyles';

export const MAX_YEARS = 40;

const CostTimeSeries = React.memo(() => {
  const { review } = useReviewState();

  const getData = () => {
    if (!review.asset || !review.project) {
      return [];
    }

    const series = review.assetProjectTimeSeries.filter((serie) => {
      return (
        (!review.asset || serie.asset === review.asset) &&
        (!review.project || serie.projectName === review.project) &&
        serie.category === review.costCategory &&
        (review.timing === TimingTypes.none || TimingValues[review.timing] === serie.setting)
      );
    });

    if (series.length === 0) {
      return [];
    }

    const years = [...Array(59)].map((item, index) => index + 2022);

    return series.map((serie) => {
      return years.slice(0, MAX_YEARS).map((year) => {
        return {
          x: year,
          y: serie.timeseries[year],
          setting: serie.setting,
        };
      });
    });
  };

  const data = getData();

  const handleXTickFormat = (value: string) => value;
  const handleYTickFormat = (value: string) => value;

  return (
    <CostTimeSeriesRoot>
      <XYPlot width={866} height={350}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickFormat={handleXTickFormat}
          title="Year"
          tickLabelAngle={-45}
          style={{
            title: {
              fontSize: '12px',
            },
          }}
        />
        <YAxis
          tickFormat={handleYTickFormat}
          title={`${CostCategoryLabels[review.costCategory!]} (${
            review.categoryLabelUnitMapping[review.costCategory!]
          })`}
          style={{
            title: {
              fontSize: '12px',
              textAnchor: 'start',
              transform: 'translate(10px, 6px) rotate(0deg)',
            },
          }}
        />
        {data.map((dataItem, index) => {
          return (
            <VerticalBarSeriesCanvas
              colorType="literal"
              stroke={getColor(dataItem[0])}
              barWidth={15}
              data={dataItem}
              key={index}
            />
          );
        })}
      </XYPlot>
    </CostTimeSeriesRoot>
  );
});

CostTimeSeries.displayName = 'CostTimeSeries';

export { CostTimeSeries };
