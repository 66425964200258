import * as React from 'react';
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextInput,
  FormGroup,
  Loading,
  InlineNotification,
} from 'carbon-components-react';
import update from 'immutability-helper';

import { makeLoginRequest } from './loginApi';

import logo from '../../img/mark.png';

import * as css from './LoginStyles';

interface LoginProps {
  handleLogin: (state: boolean) => void;
  validating: boolean;
}

export interface User {
  username: string;
  password: string;
}

const initialUser: User = {
  username: '',
  password: '',
};

interface Errors extends User {
  global: string;
}

const Login = React.memo<LoginProps>(
  (props: LoginProps): JSX.Element => {
    const { handleLogin, validating } = props;

    const usernameInput = React.useRef<HTMLInputElement | null>(null);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<User>(initialUser);
    const [error, setError] = React.useState<any>(null);

    React.useEffect(() => {
      if (usernameInput.current && !validating) {
        usernameInput.current.focus();
      }
    }, [validating]);

    const handleSubmit = (): void => {
      setLoading(true);
      setError(null);
      makeLoginRequest(user, handleLogin, setError, setLoading);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.code === 'Enter') {
        handleSubmit();
      }
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setUser(update(user, { [event.target.name]: { $set: event.target.value } }));
    };

    const errors: Errors = {
      username: error?.find
        ? error.find((errorItem: { loc: string[] }) => errorItem.loc[1] === 'username')?.msg
        : undefined,
      password: error?.find
        ? error.find((errorItem: { loc: string[] }) => errorItem.loc[1] === 'password')?.msg
        : undefined,
      global: error && !error.find && error,
    };

    if (validating) {
      return <Loading />;
    }

    return (
      <>
        <ComposedModal
          className={css.composedModal}
          preventCloseOnClickOutside
          id="login-dialog"
          open
          onClose={() => false}
          onKeyDown={handleKeyDown}
        >
          {isLoading && <Loading />}
          <ModalHeader title="Neptune F3B" />
          <ModalBody hasForm>
            <FormGroup legendText="">
              <TextInput
                required
                ref={usernameInput}
                name="username"
                id="login-username"
                labelText="Username"
                placeholder="Username"
                value={user.username}
                invalid={!!errors.username}
                invalidText={errors.username}
                onChange={handleChangeInput}
              />
              <TextInput.PasswordInput
                required
                id="login-password"
                labelText="Password"
                placeholder="Password"
                name="password"
                value={user.password}
                onChange={handleChangeInput}
                invalid={!!errors.password}
                invalidText={errors.password}
                tooltipPosition="left"
              />
            </FormGroup>
            {errors.global && (
              <InlineNotification
                lowContrast
                hideCloseButton
                kind="error"
                title="Failed to login"
                subtitle={errors.global}
              />
            )}
            <div className="logo">
              Powered by{' '}
              <a href="https://whitespace.energy/" rel="noopener noreferrer" target="_blank">
                <img className={css.logo} src={logo} alt="White Space Energy" title="White Space Energy" />
              </a>
            </div>
          </ModalBody>
          <ModalFooter primaryButtonText="Log in" onRequestSubmit={handleSubmit} />
        </ComposedModal>
      </>
    );
  },
);

Login.displayName = 'Login';

export { Login };
