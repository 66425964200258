import styled from '@emotion/styled/macro';

export const SettingsPaneRoot = styled.div`
  background-color: ${(props) => props.theme.uiBackground};
  color: ${(props) => props.theme.interactive03};
  padding: 0;

  position: relative;
  overflow-y: scroll;
  
  .bx--accordion__title {
    color: ${(props) => props.theme.text01};
  }

  .bx--label {
    color: ${(props) => props.theme.text02};
    margin-bottom: 0.5rem;
  }

  .dark .bx--accordion__item:last-child.bx--accordion__item--active {
    border-bottom: none;
  }

  .bx--form-item {
    color: ${(props) => props.theme.text01};
    font-size: 14px;

    .bx--radio-button__appearance,
    .bx--checkbox-label::before {
      border-color: ${(props) => props.theme.text01};
    }
    .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
      background-color: ${(props) => props.theme.text01};
    }
    .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance {
      border-color: ${(props) => props.theme.text01};
    }
    .bx--checkbox:checked + .bx--checkbox-label::before {
      background: none;
      border-color: ${(props) => props.theme.text01};
    }
  }
`;
