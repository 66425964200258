export interface ReviewState {
  asset: string | null;
  assets: string[];
  project: string | null;
  projects: string[];
  productionCategory: string;
  productionCategories: string[];
  costCategory: string;
  costCategories: string[];
  timing: TimingTypes;
  assetProjectTimeSeries: AssetProjectTimeSeries[];
  categoryLabelUnitMapping: {
    [key: string]: string;
  };
}

export interface AssetProjectTimeSeries {
  asset: string;
  category: string;
  projectName: string;
  setting: string;
  f17FidTimingsSetting: string;
  tariffReceivingAsset: number;
  timeseries: {
    [key: number]: number;
  };
  unit: string;
}

export enum TimingTypes {
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export const TimingLabels = {
  none: 'Choose an option',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const TimingValues = {
  low: 'L',
  medium: 'M',
  high: 'H',
};

export const CostCategoryLabels = {
  'facility capex': 'Facility capex',
  'processing tariff': 'Processing tariff',
  'transport TPC': 'Transport TPC',
  opex: 'Opex',
  'drilling capex': 'Drilling capex',
  abex: 'Abex',
  'oil tariff': 'Oil tariff',
  'maintenance capex': 'Maintenance capex',
  'tariff income from 3rd party': 'Tariff income from 3rd party',
};

export const ProductionCategoryLabels = {
  gas: 'Gas',
  condensate: 'Condensate',
  oil: 'Oil',
  hydrogen: 'Hydrogen',
  'carbon intensity': 'Carbon intensity',
};
