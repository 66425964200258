export enum NotificationType {
  disabled,
  success,
  error,
}

export interface NotificationState {
  title?: string;
  caption: string;
  notificationIndex: number;
  notificationType: NotificationType;
}
