import axios, { AxiosResponse } from 'axios';

import applyCaseMiddleware from 'axios-case-converter';
import {
  ScenarioMappings, StrategyComplexities,
  StrategyMappings,
  StrategyScenarioEconomicResultMetrics
} from '../state/StrategyStateTypes';
import { AssetProjectTimeSeries } from '../state/ReviewStateTypes';

export const fetchAssetProjectTimeSeriesData = (): Promise<AxiosResponse<AssetProjectTimeSeries[]>> => {
  const client = applyCaseMiddleware(axios.create());

  return client.get('/data/asset_project_timeseries.json');
};

export const fetchStrategies = (): Promise<AxiosResponse<StrategyMappings>> => {
  const client = axios.create();

  return client.get('/data/strategy_mapping.json');
};

export const fetchScenarios = (): Promise<AxiosResponse<ScenarioMappings>> => {
  const client = axios.create();

  return client.get('/data/scenario_mapping.json');
};

export const fetchEconomicResults = (): Promise<AxiosResponse<StrategyScenarioEconomicResultMetrics>> => {
  const client = axios.create();

  return client.get('/data/economic_results.json');
};

export const fetchComplexities = (): Promise<AxiosResponse<StrategyComplexities>> => {
  const client = axios.create();

  return client.get('/data/strategy_complexity.json');
};
