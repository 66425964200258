import { EconomicResultMetrics } from '../state/StrategyStateTypes';
import { AxesRange } from '../components/templates/StrategyCharts/organisms/molecules';
import { getPoint } from './Log';

export const getFilteredData = (metrics: EconomicResultMetrics[], selectedStrategyId: string, isLog: boolean) => {
  const selected = metrics.find((metric) => {
    return selectedStrategyId && selectedStrategyId === metric.strategyId && (isLog || metric.profitability_index < 10);
  });

  const filteredData = selected
    ? [
        ...metrics.filter((metric) => {
          return (
            (!selectedStrategyId || selectedStrategyId !== metric.strategyId) &&
            (isLog || metric.profitability_index < 10)
          );
        }),
        selected,
      ]
    : metrics.filter((metric) => {
        return isLog || metric.profitability_index < 10;
      });

  return filteredData;
};

export const getRange = (metrics: EconomicResultMetrics[]) => {
  const range = metrics.reduce<AxesRange>(
    (accumulator, nextMetric) => {
      return {
        x: {
          min: Math.min(accumulator.x.min, nextMetric.discounted_cashflow),
          max: Math.max(accumulator.x.max, nextMetric.discounted_cashflow),
        },
        y: {
          min: Math.min(accumulator.y.min, nextMetric.profitability_index),
          max: Math.max(accumulator.y.max, nextMetric.profitability_index),
        },
      };
    },
    { x: { max: 0, min: Infinity }, y: { max: 0, min: Infinity } },
  );

  return range;
};

const TICKS = [1, 5, 10, 20, 30, 40];

export const getTickValues = (range: AxesRange) => {
  return TICKS.map((tick) => {
    return {
      value: tick,
      point: getPoint(tick, { minValue: range.y.min, maxValue: range.y.max }),
    };
  });
};
