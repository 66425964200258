import { EconomicResultMetrics, ScenarioMappings } from './StrategyStateTypes';
import {
  NeptuneHighLevels,
  StrategoryChoicePermutations,
  StrategyLevels,
  StrategyToggleBool,
} from './StrategyStateConstants';

const ETO_X_MULTIPLIER = 30;
const ETO_X_OFFSET = 30;

const ETO_Y_MULTIPLIER = 100;
const ETO_Y_OFFSET = 50;

const ETO_SIZE_MULTIPLIER = 12;

export const findScenarioId = (
  scenarios: ScenarioMappings,
  hydrocarbonPriceSetting: StrategyLevels,
  oilPriceDiscountSetting: StrategyLevels,
  discountRateSetting: StrategyLevels,
  assetProjectInfoSetting: StrategyLevels,
  f17FIDTimingsSetting: StrategyLevels,
  co2PricingSetting: StrategyLevels,
  riserTowerAndIncrementalProcessingTariffSetting: StrategyLevels,
  resevoirOutcome: StrategyLevels,
  operatingExpenditure: StrategyLevels,
  capitalExpenditure: StrategyLevels,
  F5AAndBOverF3B: boolean,
  F17OverF3B: boolean,
  F6IJsselOverF3B: boolean,
  PolluxCastorOverF3B: boolean,
  F4CShallowOverF3B: boolean,
  F4CDeepOverF3B: boolean,
): string => {
  const keys = Object.keys(scenarios);

  const result = keys.find((key) => {
    const scenario = scenarios[key];

    return (
      scenario.reservoir_outcome === resevoirOutcome &&
      scenario.operational_expenditure === operatingExpenditure &&
      scenario.capital_expenditure === capitalExpenditure &&
      scenario.hydrocarbon_price_setting === hydrocarbonPriceSetting &&
      scenario.oil_price_discount_setting === oilPriceDiscountSetting &&
      scenario.discount_rate_setting === discountRateSetting &&
      scenario.asset_project_info_setting === assetProjectInfoSetting &&
      scenario.F17_FID_timings_setting === f17FIDTimingsSetting &&
      scenario.co2_pricing_setting === co2PricingSetting &&
      scenario.riser_tower_and_incremental_processing_tariff_setting ===
        riserTowerAndIncrementalProcessingTariffSetting &&
      scenario['F5-A and B over F3-B'] === (F5AAndBOverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF) &&
      scenario['F17 over F3-B'] === (F17OverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF) &&
      scenario['F6-IJssel over F3-B'] === (F6IJsselOverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF) &&
      scenario['Pollux & Castor over F3-B'] ===
        (PolluxCastorOverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF) &&
      scenario['F4-C Shallow over F3-B'] === (F4CShallowOverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF) &&
      scenario['F4-C Deep over F3-B'] === (F4CDeepOverF3B ? StrategyToggleBool.ON : StrategyToggleBool.OFF)
    );
  });

  return result!;
};

export const getChoiceTotals = (
  filteredMetricData: EconomicResultMetrics[],
  isInRange: (metric: EconomicResultMetrics) => boolean,
) => {
  const result = {};
  const keys = Object.keys(StrategoryChoicePermutations);

  keys.forEach((key) => {
    const choices = StrategoryChoicePermutations[key];

    result[key] = {};

    choices.forEach((item: string) => {
      result[key][item] = {
        valid: 0,
        total: 0,
      };
    });
  });

  filteredMetricData.forEach((metric) => {
    const inRange = isInRange(metric);

    keys.forEach((key) => {
      result[key][metric.strategy![key]].total += 1;
    });

    if (!inRange) {
      return;
    }

    keys.forEach((key) => {
      result[key][metric.strategy![key]].valid += 1;
    });
  });

  return result;
};

export const convertSlider = (sliderValue: number): StrategyLevels => {
  if (sliderValue === 1) {
    return StrategyLevels.low;
  }
  if (sliderValue === 2) {
    return StrategyLevels.medium;
  }

  return StrategyLevels.high;
};

export const convertNeptuneLevels = (level: NeptuneHighLevels): StrategyLevels => {
  if (level === NeptuneHighLevels.none) {
    return StrategyLevels.low;
  }
  if (level === NeptuneHighLevels.neptune) {
    return StrategyLevels.medium;
  }

  return StrategyLevels.high;
};

export const convertDiscount = (discount: number): StrategyLevels => {
  if (discount <= 8) {
    return StrategyLevels.high;
  }

  if (discount >= 12) {
    return StrategyLevels.low;
  }

  return StrategyLevels.medium;
};
