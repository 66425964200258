import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';

import '@whitespace/internal-whitespace-frontend-package/dist/css/index.css';
import '../node_modules/react-vis/dist/style.css';

import { LoginContainer } from './features/login/LoginContainer';
import { ApplicationProvider } from './context/ApplicationContext';

import './index.scss';

console.log('Starting application');

ReactDOM.render(
  <Router>
    <ThemeProvider>
      <ApplicationProvider>
        <LoginContainer />
      </ApplicationProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root'),
);
