import * as React from 'react';

import { useStrategyState } from '../state/StrategyState';
import { fetchScenarios } from '../api/data-fetch';

export interface UseFetchScenarioMapping {
  isFetching: boolean;
  fetchData: () => void;
  isError: boolean;
}

export const useFetchScenarioMapping = (): UseFetchScenarioMapping => {
  const { scenarioMappingFetched } = useStrategyState();
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [isError, setError] = React.useState<boolean>(false);

  const fetchData = async () => {
    try {
      setFetching(true);

      const response = await fetchScenarios();

      console.log('Scenario data', response.data);
      scenarioMappingFetched(response.data);
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    isFetching,
    fetchData,
    isError,
  };
};
