import * as React from 'react';
import { css } from '@emotion/css';

import { NeptuneHighLevels, PriceSettings, StrategyLevels } from '../../state/StrategyStateConstants';
import { FormGroup, RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { NeptuneHighSetting, RadioLevelSetting } from '../../state/StrategyStateTypes';
import { useTheme } from '../../context/ThemeContext';

export interface StrategyLevelSelectProps {
  setting: NeptuneHighSetting;
  onValueChange: (name: string, value: NeptuneHighLevels) => void;
  disabled?: boolean;
}

const NeptuneHighLevelSelect = React.memo<StrategyLevelSelectProps>((props) => {
  const { setting, onValueChange, disabled = false } = props;
  const theme = useTheme();

  const handleChange = (value: string) => {
    onValueChange(setting.name, value as NeptuneHighLevels);
  };

  const NeptuneHighLevelSelectRoot = css`
    margin-bottom: ${theme.spacing05};
  `;

  return (
    <FormGroup className={NeptuneHighLevelSelectRoot} legendText={setting.title}>
      <RadioButtonGroup name={setting.name} valueSelected={setting.value} onChange={handleChange} disabled={disabled}>
        <RadioButton id={`${setting.name}_none`} labelText="none" value={NeptuneHighLevels.high} disabled={disabled} />
        <RadioButton
          id={`${setting.name}_neptune`}
          labelText="Npt"
          value={NeptuneHighLevels.neptune}
          disabled={disabled}
        />
        <RadioButton id={`${setting.name}_high`} labelText="High" value={NeptuneHighLevels.none} disabled={disabled} />
      </RadioButtonGroup>
    </FormGroup>
  );
});

NeptuneHighLevelSelect.displayName = 'StrategyLevelSelect';

export { NeptuneHighLevelSelect };
