import * as React from 'react';
import { Toggle, ToggleProps } from 'carbon-components-react';

import { css } from '@emotion/css';

import theme from '../../styles/theme';

const SettingsToggle = React.memo<ToggleProps>((props) => {
  const toggleClass = css`
    margin-bottom: ${theme.spacing05};

    .bx--form-item {
      flex-direction: row;
    }

    .bx--toggle-input__label {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      color: #f4f4f4;
      font-size: 12px;

      .bx--toggle__switch {
        margin-top: 0;
        margin-right: 23px;
      }
    }
  `;

  return (
    <div className={toggleClass}>
      <Toggle {...props} size="sm" />
    </div>
  );
});

SettingsToggle.displayName = 'SettingsToggle';

export { SettingsToggle };
