import styled from '@emotion/styled';

export const StrategyChartsRoot = styled.div`
  margin-top: ${(props) => props.theme.spacing05};
  background-color: white;

  display: grid;
  grid-gap: 0;
  grid-template-columns: 550px 550px auto;
`;

export const CenterColumn = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`
